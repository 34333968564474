<template>
    <DataExport v-on:close-window="closeExportWindow" :user-token="userToken" :wims_ids="exportList.wims_ids" :start="exportList.start" :end="exportList.end" v-show="showExportWindow" :display-msg="exportMessage" :file-name="exportFileName" />
    <div class="spinner-border mt-4" role="status" v-show="!initialLoadComplete">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div class="container" v-show="initialLoadComplete">
        <router-link to="/">Back to Map</router-link>
        <p v-show="(errorMessage != '')">{{ errorMessage }}</p>
        <div v-show="!invalidStation && initialLoadComplete">
            <h1>{{ raws.station.name }} ({{ raws.station.wims_id }})</h1>
            <button v-on:click="exportData" class="btn btn-primary">Export Data</button>
            <hr>
            <ItemDetails :data="sentdata.details" cols="3" />
        </div>
    </div>
    <div class="container">
        <div class="rawsMap">
            <SimpleMap :station="raws.station" :center-station="true" :loaded="showMap" />
            <hr v-show="showMap" />
        </div>
    </div>
    <div class="container" v-show="initialLoadComplete">
        <div v-show="showLineGraph" class="adjustGraph">
            <button v-on:click="toggleSearchType" class="btn btn-secondary btn-sm">{{ searchType }}</button><br><br>
            <div v-show="specificDates">
                <!-- Show data between form -->
                Show data between <input type="date" id="start" v-model="start" min="2000-01-01" max="2020-12-31"> and <input type="date" id="end" v-model="end" min="2000-01-01" max="2020-12-31">&nbsp;
                <button v-on:click="getRAWSData(false)" class="btn btn-secondary btn-sm">Update Graph</button>
            </div>
            <div v-show="!specificDates" class="mb-3">
                <!-- Show last form -->
                Show Last <input type="number" min=1 style="width: 3em;" v-model="length">
                <select v-model="lengths.selected" v-on:change="adjustLength()">
                    <option v-for="option, key in lengths.options" :value="option.value" v-bind:key="key">
                    {{ option.text }}
                    </option>
                </select>&nbsp;
                <button v-on:click="updateHours()" class="btn btn-secondary btn-sm">Update Graph</button>
                <br>
                <div v-show="useFakeWRF">
                    <p style="font-size: .7em; margin-bottom: .1em;">WRF integration is not currently implemented.</p>
                    <p style="font-size: .7em;">The WRF data shown is calling on previously measured data for the current RAWS station to show what the integration will look like in the future.</p>
                </div>
            </div>
            <p v-show="(dataError != '')">{{ dataError }}</p>
        </div>
        <div v-show="predictionsExist && !specificDates && !modelServerReady && (mlTimer > 0) && !mlProblem">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <p class="mb-0 mt-0 d-inline ms-2 small">Checking for available prediction models...</p>
        </div>
        <div v-show="predictionsExist && !specificDates && !modelServerReady && (mlTimer == 0) && !mlProblem">
            <p class="mb-0 mt-0 d-inline small">Model server is taking a long time to respond. Please try reloading the page.</p>
        </div>
        <div v-show="modelServerReady && !specificDates">
                <button v-on:click="getPredictions(wims_id)" :disabled="allPulled || generatingPredictions" class="btn btn-secondary">
                    <span v-show="allPulled">Predictions Generated</span>
                    <span v-show="generatingPredictions && !allPulled">Generating Predictions...</span>
                    <span v-show="!allPulled && !generatingPredictions">Generate ML Predictions</span>
                </button>
                <div class="spinner-grow spinner-grow-sm ms-2" role="status" v-show="generatingPredictions">
                        <span class="visually-hidden">Loading...</span>
                </div>
                <button v-on:click="allPulled = true" v-show="tryCount > 2 && atLeastOnePulled" :disabled="allPulled || generatingPredictions" class="btn btn-danger ms-2">Force Update</button>
        </div>
        <div v-for="variable in Object.keys(predictionsPulled)" v-bind:key="variable" class="mb-0 mt-0" v-show="generatingPredictions">
            <p v-for="timeframe in Object.keys(predictionsPulled[variable])" class="m-0 py-1 small" v-bind:key="timeframe" v-show="!predictionsPulled[variable][timeframe]">Waiting on {{ timeframe }} hour {{ variableNameMap[variable] }} predictions...</p>
        </div>
        <div class="alert alert-danger small mt-3" v-show="mlErrors.length > 0" v-for="error in mlErrors" v-bind:key="error">
            {{ error }}
        </div>
        <LineGraph :data="sentdata.linegraph.temperature.data" :title="sentdata.linegraph.temperature.title" v-on:graph-ready="displayLineGraph" />
        <hr v-show="!invalidStation && initialLoadComplete">
        <LineGraph :data="sentdata.linegraph.relativeHumidity.data" :title="sentdata.linegraph.relativeHumidity.title" v-on:graph-ready="displayLineGraph" />
        <hr v-show="!invalidStation && initialLoadComplete">
        <LineGraph :data="sentdata.linegraph.windSpeed.data" :title="sentdata.linegraph.windSpeed.title" v-on:graph-ready="displayLineGraph" />
        <hr v-show="!invalidStation && initialLoadComplete">
        <LineGraph :data="sentdata.linegraph.precipitation.data" :title="sentdata.linegraph.precipitation.title" v-on:graph-ready="displayLineGraph" />
        <hr v-show="!invalidStation && initialLoadComplete">
        <LineGraph :data="sentdata.linegraph.solarRadiation.data" :title="sentdata.linegraph.solarRadiation.title" v-on:graph-ready="displayLineGraph" />
        <FooterMenu />
    </div>

</template>

<script>
// @ is an alias to /src
import LineGraph from '@/components/LineGraph.vue';
import ItemDetails from '@/components/ItemDetails.vue';
import DataExport from '@/components/DataExport.vue';
import SimpleMap from '@/components/SimpleMap.vue';
import FooterMenu from '@/components/FooterMenu.vue';

import { getCurrentUser } from 'vuefire';

const { DateTime } = require("luxon");

export default {
    name: 'RAWSDetailsView',
    components: {
        LineGraph,
        ItemDetails,
        DataExport,
        SimpleMap,
        FooterMenu
    },
    data(){
        return {
            /**
             * User token used when sending API requests.
             */
            userToken: null,
            /**
             * The WIMS ID coming from the router.
             * @example https://domain/raws/:wims_id
             */
            wims_id: this.$route.params.wims_id,
            variableNameMap: {
                'Temperature': 'Temperature',
                'Precipitation': 'Precipitation',
                'RelativeHumidity': 'Relative Humidity',
                'GustSpeed': 'Gust Speed',
                'GustAzimuth': 'Gust Azimuth',
                'WindSpeed': 'Wind Speed',
                'WindAzimuth': 'Wind Azimuth',
                'SolarRadiation': 'Solar Radiation',
            },
            /**
             * The object that stores the pulled RAWS information.
             */
            raws: {
                /**
                 * The object containing the RAWS station metadata.
                 */
                station: {
                    wims_id: null,
                    name: "",
                    metadata: {}
                },
                /**
                 * The object containing the RAWS station weather data.
                 */
                data: {},
            },
            /**
             * The object containing WRF data.
             */
            wrf: {
                data: {},
            },
            /**
             * Boolean to determine if WRF data is ready.
             */
            wrfReady: false,
            /**
             * The object containing what information was sent to components.
             */
            sentdata: {
                /**
                 * The object containing what information was sent to LineGraph components.
                 */
                linegraph: {
                    temperature: {
                        data: {},
                        title: 'Temperature'
                    },
                    relativeHumidity: {
                        data: {},
                        title: 'Relative Humidity'
                    },
                    windSpeed: {
                        data: {},
                        title: 'Wind Speed'
                    },
                    solarRadiation: {
                        data: {},
                        title: 'Solar Radiation'
                    },
                    precipitation: {
                        data: {},
                        title: 'Precipitation'
                    },
                },
                /**
                 * The object containing what information was sent to the ItemDetails component.
                 */
                details: {}
            },
            /**
             * Boolean indicating whether or not the data has been pulled from the API.
             */
            dataReady: false,
            /**
             * Boolean to show/hide the LineGraphs.
             */
            showLineGraph: false,
            /**
             * Object containing the variables (keys) and units (values) used for the RAWS station data.
             */
            variableUnits: {
                'Temperature': 'F',
                'RelativeHumidity': '%',
                'Precipitation': 'in',
                'WindSpeed': 'mph',
                'WindAzimuth': 'degrees',
                'GustSpeed': 'mph',
                'GustAzimuth': 'degrees',
                'SolarRadiation': 'W/m2'
            },
            /**
             * Number to show how many hours of data to pull from the database.
             * Used for the Show Last form.
             */
            hours: 48,
            /**
             * Number used with Show Last form.
             * @model length
             */
            length: 2,
            /**
             * Object containing lengths and their associated words.
             * Used to determine what number is displayed to the user in the Show Last form.
             */
            lengths: {
                options: [
                    {text: 'hours', value: 1},
                    {text: 'days', value: 24}
                ],
                /**
                 * The value selected from lengths.
                 * @model lengths.selected
                 */
                selected: 24
            },
            /**
             * *Manually set temporarily until live data can be pulled into the database.*
             * 
             * This indicates the last measured value for the RAWS station in the database.
             * Used with the Show Last number input to determine the start and end dates.
             * 
             */
            latest_value: DateTime.fromISO("2022-12-31T23:00:00-07:00").toUTC(),
            /**
             * The start date used with the Show Data Between search form.
             */
            start: "2022-12-25",
            /**
             * The end date used with the Show Data Between search form.
             */
            end: "2023-01-01",
            /**
             * The start date used with the DataExport component.
             * Set before pulling RAWS data based on which search form is selected.
             */
            export_start: null,
            /**
             * The end date used with the DataExport component.
             * Set before pulling RAWS data based on which search form is selected.
             */
            export_end: null,
            /**
             * Boolean to indicate whether the supplied WIMS ID is a valid station. Changed based on API response.
             * @default true
             */
            invalidStation: true,
            /**
             * String to display an error message to the user. Updated based on API response.
             */
            errorMessage: "",
            /**
             * String used to display an error message to the user. Used with the Show Data Between form when start comes before end.
             */
            dataError: "",
            /**
             * Object used to send list of IDs to the DataExport component.
             * @prop export-list (DataExport)
             */
            exportList: {
                wims_ids: [],
                start: null,
                end: null
            },
            /**
             * String used to send displayed message to the DataExport component.
             * @prop display-message (DataExport)
             */
            exportMessage: '',
            /**
             * String used to send file name to the DataExport component.
             * @prop file-name (DataExport)
             */
            exportFileName: '',
            /**
             * Boolean to show/hide the DataExport component.
             */
            showExportWindow: false,
            /**
             * Boolean to switch between Show Data Between and Show Last forms.
             */
            specificDates: false,
            /**
             * String for the displayed text in the form switch button.
             */
            searchType: 'Search Using Specific Dates',
            /**
             * Boolean to determine if the graphs are currently displaying hours before or
             * hours between start and end.
             */
            hoursBeforeLatest: true,
            /**
             * Object for storing the predicted variables and varying timeframes for the
             * RAWS station.
             */
            predictions: {},
            /**
             * Boolean to indicate whether prediction models exist for this station.
             * 
             * If so, show the generate predictions button.
             */
            predictionsExist: false,
            /**
             * Object of booleans indicating whether the predicted values are already pulled.
             * 
             * Essentially, we don't want to pull them again.
             */
            predictionsPulled: {},
            /**
             * Boolean indicating if the predictions are currently being pulled.
             * 
             * To hide the button and show a spinner.
             */
            generatingPredictions: false,
            /**
             * Boolean indicating if all of the predictions have been pulled.
             * 
             */
            allPulled: false,
            /**
             * Boolean used to turn on and off fake WRF data process.
             */
            useFakeWRF: false,
            /**
             * Int representing the number of tries to pull ML predictions.
             */
            tryCount: 0,
            /**
             * String used to display error message from ML predictions.
             */
            mlErrors: [],
            /**
             * Boolean used to indicate if at least one ML prediction set is pulled.
             * 
             * Used with the force update button.
             */
            atLeastOnePulled: true,
            /**
             * Boolean indicating that the initial page load is complete.
             */
            initialLoadComplete: false,
            /**
             * Boolean to show the SimpleMap.
             * 
             * Needed to prevent styling issues of initializing map before container is shown.
             */
            showMap: false,
            /**
             * List of available prediction models for this station.
             */
            modelList: [],
            /**
             * Boolean indicating if the model server is ready to go.
             */
            modelServerReady: false,
            /**
             * Timer for checking for ML models.
             */
            mlTimer: 0,
            /**
             * Boolean to indicate there's a problem with ML stuff.
             */
            mlProblem: false
        }
    },
    methods: {
        /**
         * Adjusts the hours variable and then prompts to request data.
         */
        updateHours() {
            if((this.length * this.lengths.selected) <= 0){
                this.hours = 1;
            } else {
                this.hours = this.length * this.lengths.selected;
            }
            this.dataReady = false;
            this.getRAWSData(true);
        },
        /**
         * Updates length when the length amount is adjusted (hours vs. days).
         */
        adjustLength() {
            if(this.lengths.selected == 24){
                this.length = Math.ceil(this.length / this.lengths.selected);
            } else {
                this.length = this.length * 24;
            }
        },
        /**
         * Displays LineGraph component if graphReady is true.
         * @param {Boolean} graphReady 
         */
        displayLineGraph(graphReady){
            if(graphReady){
                this.showLineGraph = true;
            }
        },
        /**
         * Fetches RAWS station metadata from API.
         */
        getStationData() {
            fetch(`/api/raws/${this.wims_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    if(response.status == 422){
                        //unprocessable entity
                        this.errorMessage = "The provided WIMS ID is malformed, please try again.";
                    } else if(response.status == 404){
                        //not found
                        this.errorMessage = "The provided WIMS ID does not exist in the database.";
                    } else {
                        //all other errors
                        this.errorMessage = "An internal server error occurred.";
                    }
                    this.invalidStation = true;
                } else {
                    this.raws.station = data;
                    this.invalidStation = false;
                    this.getFakeWRFData();
                }
            }).finally(this.checkForPredictionModel);
        },
        /**
         * Fetches predictions.
         * 
         * @param {Number} station - wims_id of station to predict.
         * @param {str} variable - Variable to predict.
         * @param {Number} timeframe - Timeframe to predict.
         */
        async getMLPredictions(station, variable, timeframe){
            this.generatingPredictions = true;
            await fetch(`/api/raws/predictions/${station}/${variable}/${timeframe}`, {
                method: "GET",
                headers: {
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    //api endpoint can give a 404, 422, and 500
                    //handling this though by just giving a null
                    this.predictions[variable][timeframe] = null;
                    this.mlErrors.push(`Unable to pull ${timeframe} hour ${this.variableNameMap[variable]} predictions. Please try again.`)
                    this.generatingPredictions = false;
                    this.mlProblem = true;
                } else {
                    this.predictionsPulled[variable][timeframe] = true;
                    this.predictions[variable][timeframe] = data;
                    this.atLeastOnePulled = true;
                    if(this.checkPulled()){
                        this.generatingPredictions = false;
                        this.allPulled = true;
                    }
                }
            });
            return true;
        },
        /**
         * Get all possible RAWS station predictions.
         * @param {int} station - wims_id of the station to get predictions for.
         */
        getPredictions(station){
            const self = this;
            this.mlProblem = false;
            this.mlErrors = [];
            this.tryCount++;
            var variables = Object.keys(this.predictions);
            for(let i = 0; i < variables.length; i++){
                var timeframes = Object.keys(this.predictions[variables[i]]);
                for(let j = 0; j < timeframes.length; j++){
                    if(!this.predictionsPulled[variables[i]][timeframes[j]]){
                        this.getMLPredictions(station, variables[i], timeframes[j]).then(function() {
                            self.dataReady = true;
                        });
                    }                   
                }
            }
        },
        /**
         * Check if prediction model exists and initialize prediction objects.
         */
        checkForPredictionModel(){
            this.mlTimer = 15;
            var url = `/api/raws/predictions/models?wims_id=${this.wims_id}`;
            const self = this;
            fetch(url, {
                method: "GET",
                headers: {
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    if(response.status != 404){
                        this.mlProblem = true;
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                } else {
                    this.modelList = data;
                    this.predictionsExist = true;
                    for(let i = 0; i < this.modelList.length; i++){
                        // for each model in list
                        var split_line = this.modelList[i]['model'].split('-');
                        if(!Object.keys(this.predictions).includes(split_line[1])){
                            this.predictions[split_line[1]] = {}; //unique variables
                            this.predictionsPulled[split_line[1]] = {};
                        }
                        if(!Object.keys(this.predictions[split_line[1]]).includes(split_line[2])){
                            this.predictions[split_line[1]][split_line[2]] = {}; 
                            this.predictionsPulled[split_line[1]][split_line[2]] = false;
                        }
                    }
                }
            }).then(function(){
                if(self.predictionsExist){
                    var testVar = Object.keys(self.predictions)[0];
                    var testTime = Object.keys(self.predictions[testVar])[0];
                    fetch(`/api/raws/predictions/${self.wims_id}/${testVar}/${testTime}/metadata`,{
                        method: "GET",
                        headers: {
                            "Authorization": self.userToken
                        }
                    }).then(async res => {
                        const modelMetadata = await res.json();
                        if(!res.ok){
                            self.mlProblem = true;
                            const error = (modelMetadata && modelMetadata.message) || res.statusText;
                            return Promise.reject(error);
                        } else {
                            self.modelServerReady = true;                            
                        }
                    });
                }
                
            });
        },
        /**
         * Fetches RAWS station weather data from API.
         * @param {Number} hoursBeforeLatest - If not empty, pull hoursBeforeLatest number of hours before last measured date for RAWS station.
         */
        getRAWSData(hoursBeforeLatest) {
            let fetch_url = "";
            if(hoursBeforeLatest){
                this.hoursBeforeLatest = true;
                fetch_url = `/api/raws/${this.wims_id}/data?start=${this.latest_value.minus({hours: this.hours})}&end=${this.latest_value}`;
                this.export_start = this.latest_value.minus({hours: this.hours});
                this.export_end = this.latest_value;
            } else {
                this.hoursBeforeLatest = false;
                fetch_url = `/api/raws/${this.wims_id}/data?start=${this.start + 'T00:00:00'}&end=${this.end + 'T23:59:59'}`
                this.export_start = this.start + 'T00:00:00';
                this.export_end = this.end + 'T23:59:59';
            }
            fetch(fetch_url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    if(response.status == 422){
                        this.dataError = 'The end date cannot be before the start. Please try again.'
                    }
                } else {
                    this.dataError = '';
                    this.raws.data = data;
                    this.dataReady = true;
                }
            })
            this.initialLoadComplete = true;
        },
        /**
         * This is a function to generate fake WRF data. Can be replaced in the future once
         * data ingress and WRF integration is completed.
         */
        getFakeWRFData(){
            let fetch_url = `/api/raws/${this.wims_id}/data?start=${this.latest_value.minus({hours: 181})}&end=${this.latest_value}`;
            fetch(fetch_url,{
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    this.dataError = 'An internal server error occurred.';
                } else {
                    this.wrf.data = data;
                    let fake_times = [];
                    for(let i = 0; i < data.time.length; i++){
                        fake_times.push(this.latest_value.plus({hours: i}).toISO());
                    }
                    this.wrf.data.fake_times = fake_times;
                    this.wrfReady = true;
                }
            });
        },
        /**
         * Sends data to LineGraph components to display.
         */
        sendLineGraphData(){
            this.sentdata.linegraph.temperature.data = this.generateGraphData([this.raws.data.Temperature], ['Temperature'], this.raws.data.time, this.hoursBeforeLatest, (this.predictionsExist ? this.predictionsPulled['Temperature']['24'] : false), false);
            this.sentdata.linegraph.relativeHumidity.data = this.generateGraphData([this.raws.data.RelativeHumidity], ['RelativeHumidity'], this.raws.data.time, this.hoursBeforeLatest, (this.predictionsExist ? this.predictionsPulled['RelativeHumidity']['24'] : false), false);
            this.sentdata.linegraph.windSpeed.data = this.generateGraphData([this.raws.data.WindSpeed], ['WindSpeed'], this.raws.data.time, this.hoursBeforeLatest, (this.predictionsExist ? this.predictionsPulled['WindSpeed']['24'] : false), false);
            this.sentdata.linegraph.precipitation.data = this.generateGraphData([this.raws.data.Precipitation], ['Precipitation'], this.raws.data.time, this.hoursBeforeLatest, false, false);
            this.sentdata.linegraph.solarRadiation.data = this.generateGraphData([this.raws.data.SolarRadiation], ['SolarRadiation'], this.raws.data.time, this.hoursBeforeLatest, false, false);
        },
        /**
         * Sends data to ItemDetails component to display.
         */
        sendDetailsData(){
            let sendlist = [];
            for(let i = 0; i < Object.keys(this.raws.station.metadata).length; i++){
                if(this.raws.station.metadata[Object.keys(this.raws.station.metadata)[i]] != null){
                    sendlist.push([Object.keys(this.raws.station.metadata)[i], this.raws.station.metadata[Object.keys(this.raws.station.metadata)[i]] ]);
                }
            }
            this.sentdata.details = sendlist;
        },
        /**
         * Generates plotly compatible scatter traces using data, variable names, and time.
         * @param {Array} data - Array of RAWS station data Arrays
         * @param {Array} variables - Array of variable names
         * @param {Array} time - Array of RAWS station data times
         * @param {Boolean} showPredictions - On true, allows prediction traces to be added.
         * @param {Boolean} hasML - On true, adds ML-related traces.
         * @param {Boolean} hasWRF - On true, adds WRF-related traces.
         */
        generateGraphData(data, variables, time, showPredictions, hasML, hasWRF){
            let traces = [];
            for(let i = 0; i < data.length; i++){
                //ml predictions start
                if(hasML && showPredictions){
                    var colors = ['firebrick', 'orange', 'seagreen']
                    var timeframes = Object.keys(this.predictions[variables[i]]);
                    for(let j = 0; j < timeframes.length; j++){
                        if(this.predictions[variables[i]][timeframes[j]] != null){
                            let x_preds = this.predictions[variables[i]][timeframes[j]]['time'];
                            x_preds.unshift(time[time.length-1])
                            let y_preds = this.predictions[variables[i]][timeframes[j]]['predictions'];
                            y_preds.unshift(data[i][data[i].length-1])
                            traces.push({
                                x: x_preds,
                                y: y_preds,
                                mode: 'lines',
                                type: 'scatter',
                                name: `Predicted ${variables[i]} (${timeframes[j]}HR)`,
                                hovertemplate: `Predicted ${variables[i]}: %{y:.2f}${this.variableUnits[variables[i]]} (rounded)<br>%{x} (+00:00)`,
                                line: {
                                    color: colors[j]
                                }
                            }) 
                        }
                    }
                }
                //ml predictions end

                traces.push({
                    x: time,
                    y: data[i],
                    type: 'scatter',
                    mode: 'lines',
                    name: `${variables[i]} (${this.variableUnits[variables[i]]})`,
                    hovertemplate: `${variables[i]}: %{y}${this.variableUnits[variables[i]]}<br>%{x} (+00:00)`,
                    line: {
                        color: 'steelblue'
                    }
                });

                //fake wrf processing begin
                if(showPredictions && hasWRF){
                    this.wrf.data[variables[i]][0] = data[i][data[i].length-1];
                    traces.push({
                        x: this.wrf.data.fake_times,
                        y: this.wrf.data[variables[i]],
                        type: 'scatter',
                        mode: 'lines',
                        name: `${variables[i]} (WRF) (simulated)`,
                        hovertemplate: `${variables[i]}: %{y}${this.variableUnits[variables[i]]}<br>%{x} (+00:00)`,
                        visible: 'legendonly',
                        line: {
                            color: 'black'
                        }
                    })
                }
                //fake wrf processing end
            }
            return traces;
        },
        /**
         * Toggles between Show Data Between and Show Last forms.
         */
        toggleSearchType(){
            if(this.specificDates){
                this.specificDates = false;
                this.searchType = 'Search Using Specific Dates'
            } else {
                this.specificDates = true;
                this.searchType = 'Search Using Latest Data'
            }
        },
        /**
         *  Prepares list, message, and filename to send to DataExport component.
         */
        exportData(){
            let export_temp = {
                start: this.export_start,
                end: this.export_end,
                wims_ids: [this.raws.station.wims_id],
                fod_ids: []
            }
            this.exportFileName = this.raws.station.name.replace(/[^a-zA-Z0-9]/g, '_') + "_" + DateTime.fromISO(this.export_start).toFormat('yyyyMMdd') + "-" + DateTime.fromISO(this.export_end).toFormat('yyyyMMdd');
            this.exportMessage = `${this.raws.station.name} (${DateTime.fromISO(this.export_start).toFormat('yyyy-MM-dd')} - ${DateTime.fromISO(this.export_end).toFormat('yyyy-MM-dd')})`;
            this.exportList = export_temp;
            this.showExportWindow = true;

        },
        /**
         * Closes DataExport component.
         */
        closeExportWindow(){
            this.showExportWindow = false;
        },
        /**
         * Checks if all of the available models' predictions have been pulled.
         */
        checkPulled(){
            var variables = Object.keys(this.predictions);
            var timeframes = Object.keys(this.predictions[variables[0]])
            for(let i = 0; i < variables.length; i++){
                for(let j = 0; j < timeframes.length; j++){
                    if(!this.predictionsPulled[variables[i]][timeframes[j]]){
                        return false;
                    }
                }
            }
            return true;
        }
    },
    mounted() {
        //On mount, pull station metadata.
        if(process.env.VUE_APP_DEV_MODE == "true"){
            //env variables are strings, hence the check above
            console.log("Running in developer mode.");
            this.userToken = null;
            this.userTokenReady = true;
            this.getStationData();
        } else {
            const self = this;
            getCurrentUser().then(async function (userInfo) {
                let user = await userInfo;
                user.getIdToken().then(async function(token) {
                    self.userToken = token;
                    self.userTokenReady = true;
                }).catch(function (error) {
                    console.log(error)
                }).then(function () {
                    self.getStationData();
                });
            }).catch( function (error) {
                console.log(error);
            });            
        }        
    },
    watch: {
        /**
         * Pulls RAWS data if provided WIMS ID is valid and WRF data is ready
         */
        wrfReady: function(){
            if(!this.invalidStation && this.wrfReady){
                this.getRAWSData(true);
            }
        },
        /**
         * Generates graphs and shows details when data is ready.
         */
        dataReady: function(){
            if(this.dataReady && this.initialLoadComplete){
                this.sendLineGraphData();
                this.sendDetailsData();
                this.dataReady = false;
            }
        },
        initialLoadComplete: function(){
            if(this.initialLoadComplete){
                this.sendLineGraphData();
                this.sendDetailsData();
                //waiting to load the map until rest of page is shown
                this.showMap = true;
            }
        },
        /**
         * Adjusts the title of the webpage to the one listed in the router.
         */
         $route: {
            immediate: true,
            handler(to){
                document.title = to.meta.title || 'Fire Weather';
            }
        },
        /** 
         * Checks to see if all ML models' data have been pulled. If so, regenerate graphs.
         */
        allPulled: function(){
            if(this.allPulled){
                this.mlErrors = [];
                this.dataReady = true;
            }
        },
        /**
         * Timer for how long to show the checking for prediction models prompt.
         */
        mlTimer: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.mlTimer--;
                    }, 1000);
                }
            },
            immediate: true
        }
    },
}

</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}
.container {
    width: 100%;
    max-width: 1080px;
    padding: 1em;
    text-align: left;
    margin: auto;
}

.adjustGraph {
    text-align: left;
}

.rawsMap{
    width: 100%;
    height: 300px;
}
</style>