import { firebaseApp } from './firebase/init.js';
import { createApp } from 'vue'
import { VueFire, VueFireAuth, getCurrentUser } from 'vuefire';
import App from './App.vue'
import router from './router'

const app = createApp(App);

if(process.env.VUE_APP_DEV_MODE != "true"){
    // skips FireAuth configuration (as well as Auth check on routes) if VUE_APP_DEV_MODE on
    var vueFireConfig = {
        firebaseApp, 
        modules: [
            VueFireAuth()
        ]
    };
    router.beforeEach(async (to) => {
        if(to.meta.requiresAuth){
            const currentUser = await getCurrentUser();
            if(!currentUser) {
                return {
                    path: '/auth',
                    query: {
                        redirect: to.fullPath
                    }
                }
            }
        }
    });
    app.use(VueFire, vueFireConfig);
}

app.use(router);
app.mount('#app');
