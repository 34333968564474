// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBG7ZsFciNrt_ztBqFe4xnBd4niPkwyi4o",
  authDomain: "nifc-fire-weather.firebaseapp.com",
  projectId: "nifc-fire-weather",
  storageBucket: "nifc-fire-weather.appspot.com",
  messagingSenderId: "472818629502",
  appId: "1:472818629502:web:875e309fff392c1243512f"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export { firebaseApp };
