<template>
    <DataExport v-on:close-window="closeExportWindow" :user-token="userToken" :wims_ids="exportList.wims_ids" :fod_ids="exportList.fod_ids" :start="exportList.start" :end="exportList.end" v-show="showExportWindow" :display-msg="exportMessage" :file-name="exportFileName" />
    <div class="container">
        <router-link to="/">Back to Map</router-link>
        <p v-show="(errorMessage != '')">{{ errorMessage }}</p>
        <div v-show="!invalidFire">
            <h1>{{ fire.FIRE_NAME == null ? '[NO NAME]' : fire.FIRE_NAME }} ({{ fire.FOD_ID }})</h1>
            <button v-on:click="exportData" class="btn btn-primary">Export Data</button>
            
            <ItemDetails :data="sentdata.fire.details" cols="3" />
        </div>
        <div class="fireMap">
            <SimpleMap :fire="fire" :station="raws.station" :center-station="false" />
            <hr />
        </div>
        <div v-show="!invalidFire">
            <hr />
            <h2>Reporting Unit</h2>
            <h3>{{ nwcg_unit.name }} ({{ nwcg_unit.id }})</h3>
            <ItemDetails :data="sentdata.nwcg.details" cols="3" />
        </div>
        <div v-show="!invalidFire">
            <hr />
            <h2>Closest RAWS Station</h2>
            <h3><router-link :to="'/raws/' + raws.station.wims_id">{{ raws.station.name }}</router-link> ({{ raws.station.wims_id }})</h3>
            <ItemDetails :data="sentdata.raws.details" cols="3" />
        </div>
        <div v-show="showLineGraph" class="adjustGraph">
            <hr />
            Show <input type="number" min=1 style="width: 3em;" v-model="length">
            <select v-model="lengths.selected" v-on:change="adjustLength()">
            <option v-for="option, key in lengths.options" :value="option.value" v-bind:key="key">
            {{ option.text }}
            </option>
            </select>&nbsp;
            Before and After Fire Occurrence
            <button v-on:click="updateHours()" class="btn btn-secondary btn-sm">Update Graph</button><br><br>
        </div>
        <LineGraph :data="sentdata.linegraph.temperatureHumidity.data" :title="sentdata.linegraph.temperatureHumidity.title" :layout="sentdata.linegraph.temperatureHumidity.layout" v-on:graph-ready="displayLineGraph" />
        <hr v-show="!invalidFire">
        <LineGraph :data="sentdata.linegraph.precipitation.data" :title="sentdata.linegraph.precipitation.title" :layout="sentdata.linegraph.precipitation.layout" v-on:graph-ready="displayLineGraph" />
        <hr v-show="!invalidFire">
        <LineGraph :data="sentdata.linegraph.solarRadiation.data" :title="sentdata.linegraph.solarRadiation.title" :layout="sentdata.linegraph.solarRadiation.layout" v-on:graph-ready="displayLineGraph" />
        <FooterMenu />
</div>
</template>

<script>
// @ is an alias to /src
import LineGraph from '@/components/LineGraph.vue';
import ItemDetails from '@/components/ItemDetails.vue';
import DataExport from '@/components/DataExport.vue';
import SimpleMap from '@/components/SimpleMap.vue';
import FooterMenu from '@/components/FooterMenu.vue';

import { getCurrentUser } from 'vuefire';

const { DateTime } = require("luxon");

export default {
    name: 'FireDetailsView',
    components: {
        LineGraph,
        ItemDetails,
        DataExport,
        SimpleMap,
        FooterMenu
    },
    data(){
        return {
            /**
             * User token used when sending API requests.
             */
            userToken: null,
            /**
             * The FOD ID coming from the router.
             * @example https://domain/fire/:fod_id
             */
            fod_id: this.$route.params.fod_id,
            /**
             * The object that stores the pulled fire information.
             */
            fire: {
                FIRE_NAME: "",
                FOD_ID: null
            },
            /**
             * The object that stores the pulled RAWS information.
             */
            raws: {
                /**
                 * The object containing the RAWS station metadata.
                 */
                station: {
                    wims_id: null,
                    name: "",
                    metadata: {}
                },
                /**
                 * The object containing the RAWS station weather data.
                 */
                data: {},
            },
            /**
             * The object that stores the pulled NWCG unit information.
             */
            nwcg_unit: {
                id: null,
                name: ''
            },
            /**
             * The object containing what information was sent to components.
             */
            sentdata: {
                /**
                 * The object containing what information was sent to LineGraph components.
                 */
                linegraph: {
                    temperatureHumidity: {
                        data: {},
                        title: 'Temperature & Relative Humidity',
                        layout: {}
                    },
                    solarRadiation: {
                        data: {},
                        title: 'Solar Radiation',
                        layout: {}
                    },
                    precipitation: {
                        data: {},
                        title: 'Precipitation',
                        layout: {}
                    },
                },
                /**
                 * The object containing what fire information was sent to the ItemDetails component.
                 */
                fire: {
                    details: {}
                },
                /**
                 * The object containing what nwcg information was sent to the ItemDetails component.
                 */
                nwcg: {
                    details: {}
                },
                /**
                 * The object containing what RAWS station information was sent to the ItemDetails component.
                 */
                raws: {
                    details: {}
                }
            },
            /**
             * Boolean indicating whether or not the RAWS data has been pulled from the API.
             */
            rawsDataReady: false,
            /**
             * Boolean indicating whether or not the fire data has been pulled from the API.
             */
            fireDataReady: false,
            /**
             * Boolean indicating whether or not the NWCG data has been pulled from the API.
             */
            nwcgDataReady: false,
            /**
             * Boolean to show/hide the LineGraphs.
             */            
            showLineGraph: false,
            /**
             * Object containing the variables (keys) and units (values) used for the RAWS station data.
             */
            variableUnits: {
                'Temperature': 'F',
                'RelativeHumidity': '%',
                'Precipitation': 'in',
                'WindSpeed': 'mph',
                'WindAzimuth': 'degrees',
                'GustSpeed': 'mph',
                'GustAzimuth': 'degrees',
                'SolarRadiation': 'W/m2'
            },
            /**
             * Number to show how many hours of data before and after fire occurrence to pull from the database.
             */
            hours: 168,
            /**
             * Number used with data form.
             * @model length
             */
            length: 7,
            /**
             * Object containing lengths and their associated words.
             * Used to determine what number is displayed to the user in the data form.
             */
            lengths: {
                options: [
                    {text: 'hours', value: 1},
                    {text: 'days', value: 24}
                ],
                /**
                 * The value selected from lengths.
                 * @model lengths.selected
                 */
                selected: 24
            },
            /**
             * Boolean to indicate whether the supplied FOD ID is a valid fire occurrence. Changed based on API response.
             * @default true
             */
            invalidFire: true,
            /**
             * String to display an error message to the user. Updated based on API response.
             */
            errorMessage: "",
            /**
             * Object used to send list of IDs to the DataExport component.
             * @prop export-list (DataExport)
             */
            exportList: {
                fod_ids: [],
                wims_ids: [],
                start: null,
                end: null
            },
            /**
             * String used to send displayed message to the DataExport component.
             * @prop display-message (DataExport)
             */
            exportMessage: {},
            /**
             * String used to send file name to the DataExport component.
             * @prop file-name (DataExport)
             */
            exportFileName: {},
            /**
             * Boolean to show/hide the DataExport component.
             */
            showExportWindow: false,
            /**
             * The start date used with the DataExport component.
             */
            export_start: null,
            /**
             * The end date used with the DataExport component.
             */
            export_end: null
        }
    },
    methods: {
        /**
         * Adjusts the hours variable and then prompts to request data.
         */
        updateHours() {
            if((this.length * this.lengths.selected) <= 0){
                this.hours = 1;
            } else {
                this.hours = this.length * this.lengths.selected;
            }
        },
        /**
         * Updates length when the length amount is adjusted (hours vs. days).
         */
        adjustLength() {
            if(this.lengths.selected == 24){
                this.length = Math.ceil(this.length / this.lengths.selected);
            } else {
                this.length = this.length * 24;
            }
        },
        /**
         * Displays LineGraph component if graphReady is true.
         * @param {Boolean} graphReady 
         */
        displayLineGraph(graphReady){
            if(graphReady){
                this.showLineGraph = true;
            }
        },
        /** 
         * Fetches fire occurrence data from API.
         */
        getFireData(){
            fetch(`/api/fires/${this.fod_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    if(response.status == 422){
                        //unprocessable entity
                        this.errorMessage = "The provided FOD ID is malformed, please try again.";
                    } else if(response.status == 404) {
                        this.errorMessage = "The provied FOD ID does not exist in the database.";
                    } else {
                        this.errorMessage = "An internal server error occurred.";
                    }
                    this.invalidFire = true;
                } else {
                    this.fire = data;
                    this.invalidFire = false;
                    this.fireDataReady = true;
                }
            })
        },
        /**
         * Fetches RAWS station metadata from API.
         */
        getStationData() {
            fetch(`/api/fires/${this.fod_id}/closestraws`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    this.errorMessage = "Could not load nearest RAWS station.";
                } else {
                    this.raws.station = data;
                    this.getRAWSData();
                }
            })
        },
        /**
         * Fetches RAWS station weather data from API.
         */
        getRAWSData() {
            this.export_start = DateTime.fromISO(this.fire['DISCOVERY_DATETIME']).minus({hours: this.hours});
            this.export_end = this.fire['CONT_DATETIME'] == null ? DateTime.fromISO(this.fire['DISCOVERY_DATETIME']).plus({hours: this.hours}) : DateTime.fromISO(this.fire['CONT_DATETIME']).plus({hours:this.hours});
            fetch(`/api/raws/${this.raws.station.wims_id}/data?start=${this.export_start}&end=${this.export_end}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    this.errorMessage = 'An internal server error has occurred.';
                } else {
                    this.errorMessage = '';
                    this.raws.data = data;
                    this.rawsDataReady = true;
                }
            })
        },
        /**
         * Fetches NWCG reporting unit data from API.
         */
        getNWCGData(){
            fetch(`/api/nwcg/${this.fire["NWCG_REPORTING_UNIT_ID"]}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok){
                    this.errorMessage = 'An internal server error has occurred.';
                } else {
                    this.errorMessage = '';
                    this.nwcg_unit = data;
                    this.nwcgDataReady = true;
                }
            });
        },
        /**
         * Sends data to LineGraph components to display.
         */
        sendLineGraphData(){
            this.sentdata.linegraph.temperatureHumidity.layout = this.generateLayout(this.fire["DISCOVERY_DATETIME"], this.fire["CONT_DATETIME"], `${this.fire.FIRE_NAME != null ? this.fire.FIRE_NAME : '[NO NAME]'}: Temperature and Relative Humidity from Closest RAWS (${this.raws.station.name})`);
            this.sentdata.linegraph.temperatureHumidity.data = this.generateGraphData(this.fire["DISCOVERY_DATETIME"], this.fire["CONT_DATETIME"], [this.raws.data.Temperature, this.raws.data.RelativeHumidity], ['Temperature', 'RelativeHumidity'], this.raws.data.time);

            this.sentdata.linegraph.precipitation.layout = this.generateLayout(this.fire["DISCOVERY_DATETIME"], this.fire["CONT_DATETIME"], `${this.fire.FIRE_NAME != null ? this.fire.FIRE_NAME : '[NO NAME]'}: Precipitation from Closest RAWS (${this.raws.station.name})`);
            this.sentdata.linegraph.precipitation.data = this.generateGraphData(this.fire["DISCOVERY_DATETIME"], this.fire["CONT_DATETIME"], [this.raws.data.Precipitation], ['Precipitation'], this.raws.data.time);

            this.sentdata.linegraph.solarRadiation.layout = this.generateLayout(this.fire["DISCOVERY_DATETIME"], this.fire["CONT_DATETIME"], `${this.fire.FIRE_NAME != null ? this.fire.FIRE_NAME : '[NO NAME]'}: Solar Radiation from Closest RAWS (${this.raws.station.name})`);
            this.sentdata.linegraph.solarRadiation.data = this.generateGraphData(this.fire["DISCOVERY_DATETIME"], this.fire["CONT_DATETIME"], [this.raws.data.SolarRadiation], ['SolarRadiation'], this.raws.data.time);
        },
        /**
         * Sends fire occurrence data to ItemDetails component to display.
         */
        sendFireDetails(){
            let sendlist = [];
            let fire_keys = Object.keys(this.fire)
            for(let i = 0; i < fire_keys.length; i++){
                if(this.fire[fire_keys[i]] != null){
                    if(fire_keys[i] != 'SHAPE' && fire_keys[i] != 'LOCATION'){
                        sendlist.push([fire_keys[i], this.fire[fire_keys[i]]]);
                    }
                }
            }
            this.sentdata.fire.details = sendlist;
        },
        /**
         * Sends NWCG reporting unit data to ItemDetails component to display.
         */
        sendNWCGDetails(){
            let sendlist = [];
            let nwcg_keys = Object.keys(this.nwcg_unit);
            for(let i = 0; i < nwcg_keys.length; i++){
                if(this.nwcg_unit[nwcg_keys[i]] != null){
                    if(nwcg_keys[i] != 'id' && nwcg_keys[i] != 'name')
                    sendlist.push([nwcg_keys[i], this.nwcg_unit[nwcg_keys[i]]]);
                }
            }
            this.sentdata.nwcg.details = sendlist;
        },
        /**
         * Sends RAWS station data to ItemDetails component to display.
         */
        sendRAWSDetails(){
            let sendlist = [];
            for(let i = 0; i < Object.keys(this.raws.station.metadata).length; i++){
                if(this.raws.station.metadata[Object.keys(this.raws.station.metadata)[i]] != null){
                    sendlist.push([Object.keys(this.raws.station.metadata)[i], this.raws.station.metadata[Object.keys(this.raws.station.metadata)[i]]]);
                }
            }
            this.sentdata.raws.details = sendlist;
        },
        /**
         * Generates plotly compatible scatter traces using data, variable names, and time.
         * @param {Array} data - Array of RAWS station data Arrays
         * @param {Array} variables - Array of variable names
         * @param {Array} time - Array of RAWS station data times
         */        
        generateGraphData(discovered, contained, data, variables, time){
            let traces = [];

            traces.push({
                x: [discovered],
                y: [0],
                type: 'scatter',
                mode: 'lines',
                name: 'Fire Discovered',
                hovertemplate: 'Discovered:<br>%{x} (+00:00)'
            });
            for(let i = 0; i < data.length; i++){
                traces.push({
                    x: time,
                    y: data[i],
                    type: 'scatter',
                    mode: 'lines',
                    name: `${variables[i]} (${this.variableUnits[variables[i]]})`,
                    hovertemplate: `${variables[i]}: %{y}${this.variableUnits[variables[i]]}<br>%{x} (+00:00)`,
                });
            }
            if(contained != null && contained != discovered){
                traces.push({
                    x: [contained],
                    y: [0],
                    type: 'scatter',
                    mode: 'lines',
                    name: 'Fire Contained',
                    hovertemplate: 'Contained:<br>%{x} (+00:00)'
                });
            }
            return traces;
        },
        /**
         * Generates a plotly compatible layout object using fire discovery and containment times.
         * @param {str} discovered - ISO DateTime string.
         * @param {str} contained - ISO DateTime string.
         * @param {*} title - Title of plot.
         */
        generateLayout(discovered, contained, title){
            let layout = {
                title: title,
                xaxis: {
                    title: 'Date/Time (UTC)',
                    type: 'date'
                },
                yaxis: {
                    title: 'Value'
                },
                shapes: [{
                    type: 'line',
                    x0: discovered,
                    y0: 0,
                    x1: discovered,
                    y1: 1,
                    yref: 'paper',
                    line: {
                        color: 'grey',
                        width: 1.5,
                        dash: 'dot'
                    }
                }]
            }

            if(contained != null && contained != discovered){
                layout.shapes.push({
                    type: 'line',
                    x0: contained,
                    y0: 0,
                    x1: contained,
                    y1: 1,
                    yref: 'paper',
                    line: {
                        color: 'grey',
                        width: 1.5,
                        dash: 'dot'
                    }
                })
            }

            return layout;
        },
        /**
         * Finds the difference in hours between a fire's discovery and containment times.
         * @param {str} discovered - ISO DateTime string.
         * @param {str} contained - ISO DateTime string.
         */
        fireDuration(discovered, contained){
            if(contained == null){
                return 0;
            } else {
                let d1 = DateTime.fromISO(discovered);
                let d2 = DateTime.fromISO(contained);
                let difference = d2.diff(d1, ["hours"]);
                return difference.values.hours;
            }
        },
        /**
         * *Not currently in use.*
         * 
         * Calculates the total number of hours of data to pull for the fire occurrence.
         * @param {str} discovered - ISO DateTime string.
         * @param {str} contained - ISO DateTime string.
         * @param {Number} hours - Number of hours before and after fire occurrence to show.
         */
        calculateHours(discovered, contained, hours){
            let fire_duration = this.fireDuration(discovered, contained);
            return (hours * 2) + fire_duration;
        },
        /**
         *  Prepares list, message, and filename to send to DataExport component.
         */
        exportData(){
            let export_temp = {
                start: this.export_start,
                end: this.export_end,
                wims_ids: [this.raws.station.wims_id],
                fod_ids: [this.fire.FOD_ID]
            }
            this.exportFileName = (this.fire.FIRE_NAME != null ? this.fire.FIRE_NAME.replace(/[^a-zA-Z0-9]/g, '_') + '_' : '') + this.fire.FOD_ID + "_" + DateTime.fromISO(this.export_start).toFormat('yyyyMMdd') + "-" + DateTime.fromISO(this.export_end).toFormat('yyyyMMdd');
            this.exportMessage = `${this.fire.FIRE_NAME != null ? this.fire.FIRE_NAME : this.fire.FOD_ID } (${this.raws.station.name}, ${DateTime.fromISO(this.export_start).toFormat('yyyy-MM-dd')} - ${DateTime.fromISO(this.export_end).toFormat('yyyy-MM-dd')})`;
            this.exportList = export_temp;
            this.showExportWindow = true;

        },
        /**
         * Closes DataExport component.
         */
        closeExportWindow(){
            this.showExportWindow = false;
        }
    },
    mounted() {
        if(process.env.VUE_APP_DEV_MODE == "true"){
            //env variables are strings, hence the check above
            this.userToken = null;
            this.userTokenReady = true;
            this.getFireData();
        } else {
            //On mount, pull fire occurrence data.
            const self = this;
            getCurrentUser().then(async function (userInfo) {
                let user = await userInfo;
                user.getIdToken().then(async function(token) {
                    self.userToken = token;
                    self.userTokenReady = true;
                }).catch(function (error) {
                    console.log(error)
                }).then(function () {
                    self.getFireData();
                });
            }).catch( function (error) {
                console.log(error);
            });
        }
        
    },
    watch: {
        /**
         * Pulls RAWS and NWCG information if fire occurrence is valid.
         */
        invalidFire: function(){
            if(!this.invalidFire){
                this.getStationData();
                this.getNWCGData();
            }
        },
        /**
         * Sends fire details to ItemDetails component when data is ready.
         */       
        fireDataReady: function(){
            if(this.fireDataReady){
                this.sendFireDetails();
                this.fireDataReady = false;
            }
        },
        /**
         * Generates graphs and shows details for RAWS station when data is ready.
         */
        rawsDataReady: function(){
            if(this.rawsDataReady){
                this.sendRAWSDetails();
                this.sendLineGraphData();
                this.rawsDataReady = false;
            }
        },
        /**
         * Sends NWCG details to ItemDetails component when data is ready.
         */  
        nwcgDataReady: function(){
            if(this.nwcgDataReady){
                this.sendNWCGDetails();
                this.nwcgDataReady = false;
            }
        },
        /**
         * Re-requests data when hours variable is changed.
         */
        hours: function(){
            this.rawsDataReady = false;
            this.getRAWSData();
        },
        /**
         * Adjusts the title of the webpage to the one listed in the router.
         */
        $route: {
            immediate: true,
            handler(to){
                document.title = to.meta.title || 'Fire Weather';
            }
        }
    }
}

</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}
.container {
    width: 100%;
    max-width: 1080px;
    padding: 1em;
    text-align: left;
    margin: auto;
    box-sizing: border-box;
}

.adjustGraph {
    text-align: center;
}

.fireMap{
    width: 100%;
    height: 300px;
}

</style>