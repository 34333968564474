import { createRouter, createWebHistory } from 'vue-router'
import MapView from '../views/MapView.vue'
import FireDetailsView from '../views/FireDetailsView.vue'
import RAWSDetailsView from '../views/RAWSDetailsView.vue'
import AuthenticationView from '@/views/AuthenticationView.vue';

var routeList = [
  {
    path: '/',
    name: 'map',
    component: MapView,
    meta: { title: 'Fire Weather - Map', requiresAuth: true }
  },
  {
    path: '/raws/:wims_id',
    name: 'raws',
    component: RAWSDetailsView,
    meta: { title: 'Fire Weather - RAWS Station Details', requiresAuth: true }
  },
  {
    path: '/fire/:fod_id',
    name: 'fire',
    component: FireDetailsView,
    meta: { title: 'Fire Weather - Fire Occurrence Details', requiresAuth: true }
  }
];
  
if(process.env.VUE_APP_DEV_MODE != "true"){
  // skips adding Auth route if VUE_APP_DEV_MODE is on
  routeList.push({
    path: '/auth',
    name: 'login',
    component: AuthenticationView,
    meta: { title: 'Fire Weather - Authentication Required', requiresAuth: false }
  })
}

const routes = routeList;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
