<template>
    <div class="tools-container">
        <div class="tools">
            
            <div class="paginator">
                <span class="section"><button v-on:click="previousPage" :disabled="!(currPage > 1) || !firesReady" title="Previous" class="btn btn-sm btn-secondary">Previous</button> 
                {{ currPage }} / {{ pages }} 
                <button v-on:click="nextPage" :disabled="!(currPage < pages) || !firesReady" title="Next page" class="btn btn-sm btn-secondary">Next</button></span>
                <span class="section">Display <input v-on:change="sendLength" v-model="perPage" type="number" min="25" max="500" step="25" :disabled="!firesReady"> Fires Per Page</span>
            </div>
            <!-- This button (and the following information button) both emit directly from themselves using 'requestExportList' (prompts the map to send a list of fires for export) and 'openMoreInformation' (sends a message to MoreInformation component to display to the user). -->
            <span class="section"><button class="btn btn-outline-light" v-on:click="$emit('requestExportList')" title="Export fires and RAWS stations viewable on map">Export Current View</button><button class="moreInfoButton" v-on:click="$emit('openMoreInformation', messages.export)">&#9432;</button></span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MapTools',
    props: ['firesReady', 'currentPage', 'pages', 'pageLength'],
    emits: ['hideTools', 'update:currentPage', 'requestExportList', 'openMoreInformation', 'changeLength'],
    data() {
        return {
            /**
             * Messages to be sent to the MoreInformation component (handled by MapView)
             */
            messages: {
                export: {
                    title: 'Exporting fires from the map',
                    message: ['This allows you to download a .zip archive of the data displayed on the map.',
                                'If there are multiple pages of fires, check the "Export All Pages" option to download the fires within the viewable bounds from each page.',
                                'If you are looking to download a single fire with the closest RAWS station data, there is also an export link on the fire details page. Otherwise, you can zoom in closely to where only the intended fires and stations are shown before exporting.']
                }
            },
            /**
             * The handler for the Fires Per Page tool, necessary since props are readonly.
             * Set by default to the passed pageLength prop.
             * @model
             */
            perPage: this.pageLength,
            /**
             * The currently viewed page of fires by the user.
             */
            currPage: this.currentPage
        }
    },
    methods: {
        /**
         * Gets called when the user clicks the Next button.
         * @event update:CurrentPage
         */
        nextPage(){
            this.currPage++;
            this.$emit('update:currentPage', this.currPage);
        },
        /**
         * Gets called when the user clicks the previous button.
         * @event update:CurrentPage
         */
        previousPage(){
            this.currPage--;
            this.$emit('update:currentPage', this.currPage);
        },
        /**
         * Gets called when the user changes the value for perPage.
         * @event changeLength
         */
        sendLength(){
            if(this.perPage > 0 && this.perPage <= 500){
                this.$emit('changeLength', this.perPage)
            } else {
                if(this.perPage <= 0){
                    this.perPage = 25;
                } else if(this.perPage > 500){
                    this.perPage = 500;
                } else {
                    this.perPage = 250;
                }
                this.$emit('changeLength', this.perPage);
            }
            
        }
    },
    watch: {
        /**
         * Watches for a change in the currentPage prop (sent from MapView) and sets local version to value.
         * Used for resetting the page back to 1 when perPage changes.
         */
        currentPage: function(){
            this.currPage = this.currentPage;
        }
    }
}
</script>

<style scoped>
.tools-container{
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.tools {
    box-sizing: border-box;
    margin: auto;
    z-index: 1001;
    position: relative;
    padding: 1em;
    background-color:rgb(37, 37, 37, 0.95);
    color: white;
    text-align: center;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px #595959 solid;
}

.paginator {
    display: inline-block;
    text-align: center;
}

.paginator button:disabled {
    color: lightgrey;
}

.moreInfoButton {
    font-size: 1em;
    background: none;
    border: none;
    margin-left: .3em;
    padding: 0;
    color: white;
}

.moreInfoButton:hover {
    font-weight: bold;
    cursor: pointer;
}

.closeButton {
    background: none;
    border: none;
    padding: 0;
    margin: .1em 0;
    color: white;
    font-size: 1.3em;
}

.closeButton:hover{
    font-weight: bold;
    cursor: pointer;
}

.section {
    display: block;
    padding: .2em .5em;
    width: 100%;
    box-sizing: border-box;
    margin-top: .5em;
}

.export {
    border: none;
    background: none;
    margin-right: .3em;
    padding: .3em 1em;
    color: white;
    font-size: .9em;
    height: 44px;
    border: 1px white solid;
}

.export:hover {
    cursor: pointer;
    background-color: #3A3A3A;
}

.close {
    text-align: left;
}

.paginator {
    margin-bottom: .1em;
}

@media screen and (min-width: 578px){
    .section {
        display: inline-block;
        padding: 0 .5em;
        margin: 0 .5em;
        width: auto;
    }

    .tools {
        text-align: left;
        padding: 1em 0;
    }

    .tools-container {
        width: auto;
    }
}
</style>