<template>
    <div class="container-export">
        <div class="export-form">
            <div class="menu">
                <button type="button" class="btn-close" aria-label="Close" v-on:click="closeWindow"></button>
                <hr />
            </div>
            <div class="content">
                <div v-show="displayMsg != undefined">
                    <p>{{ displayMsg }}</p>
                <hr />
                </div>
                <div class="exportOptions">
                    <h2>Options</h2>
                    <div class="option" v-if="fires">
                        <input type="checkbox" v-model="exportFires" id="exportFires">
                        <label for="exportFires">
                            Export Fire Occurrence Metadata ({{ numFires }})
                        </label>
                    </div>
                    <div class="option" v-if="raws">
                        <input type="checkbox" v-model="exportRAWS" id="exportRAWS">
                        <label for="exportRAWS">
                            Export RAWS Station Data ({{ numStations }})
                        </label>
                    </div>
                    <div class="option" v-if="pages">
                        <input type="checkbox" v-model="exportAllFires" id="exportAllFires"><label for="exportAllFires">
                            Export All Pages
                        </label>
                    </div>
                </div>
                <hr />
                <div class="download">
                    <p v-show="errorMessage != ''">{{ errorMessage }}</p>
                    <p v-show="!showDownloadButton">{{ downloadingMessage }}</p>
                    <button v-on:click="getFileContents" v-show="showDownloadButton" class="exportButton" title="Download exported information">Download</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const { DateTime } = require("luxon");

export default {
    name: 'DataExport',
    components: { },
    props: {
        fod_ids: {
            type: Array,
            default(){
                return [];
            }
        },
        wims_ids: {
            type: Array,
            default(){
                return [];
            }
        },
        allFires: {
            type: Array,
            default(){
                return [];
            }
        },
        start: String,
        end: String,
        fileName: String,
        displayMsg: String,
        userToken: {
            type: String,
            default(){
                return null;
            }
        }
    },
    emits: ['closeWindow'],
    data() {
        return {
            /**
             * Boolean to show/hide the download button when waiting for the API to generate the .zip file.
             */
            showDownloadButton: true,
            /**
             * Message to display while the button is hidden.
             */
            downloadingMessage: "Preparing download link...",
            /**
             * String representing the error message shown to the user.
             */
            errorMessage: "",
            /**
             * Boolean indicating that the user wants to export all fires
             * regardless of pagination.
             */
            exportAllFires: false,
            /**
             * Boolean indicating that the user wants to export RAWS stations.
             */
            exportRAWS: true,
            /**
             * Boolean indicating that the user wants to export fire occurrences.
             */
            exportFires: true,
        }
    },
    methods: {
        /**
         * Closes the DataExport component by prompting the parent.
         * @event closeWindow
         */
        closeWindow(){
            this.$emit('closeWindow');
        },
        /**
         * Fetches the file contents from the API based on the provided exportList prop.
         * @param {str} filename - The file name the user will see once prompted to download.
         */
        getFileContents(){
            this.showDownloadButton = false;
            this.downloadingMessage = "Generating file, please wait...";
            if(!this.exportRAWS && !this.exportFires){
                //neither options to export are selected, give error message
                this.errorMessage = "No data is selected to export.";
                this.showDownloadButton = true;
            } else {
                this.errorMessage = "";
                var apiExport = {
                    start: this.start,
                    end: this.end,
                    wims_ids: this.exportRAWS ? this.wims_ids : [],
                    fod_ids: this.exportFires ? (this.exportAllFires ? this.allFires : this.fod_ids) : []
                }
                fetch(`/api/export/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": this.userToken
                    },
                    body: JSON.stringify(apiExport)
                }).then(response => {
                    if(!response.ok){
                        this.errorMessage = 'An internal server error has occurred.';
                        this.showDownloadButton = true;
                    } else {
                        response.blob().then( blob => {this.downloadFile(this.generateFileName(), blob) });
                    }
                });
            }
        },
        /**
         * Handles the actual download of the file, including adding the intended filename. Used with getFileContents().
         * @param {str} filename 
         * @param {*} blob 
         */
        downloadFile(filename, blob){
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.target = "_blank";
            link.style.visibility = "hidden";
            link.dispatchEvent(new MouseEvent("click"));
            link.remove();
            this.showDownloadButton = true;
        },
        /**
         * Creates a default file name with the start and end times.
         * Used if fileName is not supplied by parent component.
         */
        generateFileName(){
            if(this.fileName == undefined){
                return "firemap_export_" + DateTime.fromISO(this.start).toFormat('yyyyMMdd') + '-' + DateTime.fromISO(this.end).toFormat('yyyyMMdd'); 
            } else {
                return this.fileName;
            }
        }
    },
    computed: {
        /**
         * Computed variable that returns a boolean representing if there are multiple pages of fires.
         * @prop allFires
         * @prop fod_ids
         * @returns boolean
         */
        pages(){
            if(this.allFires.length > this.fod_ids.length){
                return true;
            } else {
                return false;
            }
        },
        /**
         * Represents whether or not there are RAWS stations in the export list.
         * @prop wims_ids
         * @returns boolean
         */
        raws(){
            if(this.wims_ids.length > 0){
                return true;
            } else {
                return false;
            }
        },
        /**
         * Represents whether or not there are fire occurrences in the export list.
         * @prop fod_ids
         * @returns boolean
         */
        fires(){
            if(this.fod_ids.length > 0){
                return true;
            } else {
                return false;
            }
        },
        /**
         * Returns a string used to display the number of fires in the export list to the user.
         * @prop allFires
         * @prop fod_ids
         * @var exportAllFires
         * @returns String
         */
        numFires(){
            let num;
            if(this.exportAllFires) {
                num = this.allFires.length;
            } else {
                num = this.fod_ids.length;
            }
            if(num == 1){
                return String(num) + ' fire';
            } else {
                return String(num) + ' fires';
            }
        },
        /**
         * Returns a string used to display the number of RAWS stations in the export list to the user.
         * @prop wims_ids
         * @returns String
         */
        numStations(){
            if(this.wims_ids.length == 1){
                return String(this.wims_ids.length) + ' station';
            } else {
                return String(this.wims_ids.length) + ' stations';
            }
        }
    }
}
</script>

<style scoped>
.container-export {
    z-index: 2000;
    position: fixed;
    background-color: rgb(0,0,0,0.5);
    width: 100%;
    height: 100%;
}
.export-form {
    background-color: white;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    max-width: 600px;
    margin: auto;
}
.menu {
    text-align: left;
}
.content {
    margin: auto;
    text-align: left;
}
.content p {
    margin: 0;
    margin-left: 1em;
    margin-bottom: .5em;
}

.message {
    margin-left: 1em;
}

hr {
    margin-top: 1em;
    margin-bottom: 1em;
}

.exportButton {
    background-color: #ff4b00; /* Green */
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.exportOptions {
    text-align: left;
}

.exportOptions h2 {
    margin: 0;
    margin-left: .5em;
    margin-bottom: .3em;
}

.option {
    display: block;
    margin: 0;
    margin-left: 1em;
}

.option label {
    margin-left: .2em;
}

.download {
    margin: 0;
    text-align: center;
}

.download p {
    margin: 0;
    margin-bottom: 1em;
}
</style>