<template>
    <div id="simpleMap"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
/**
 * Array containing the main Leaflet map stored as map[0].
 */
const map = []; //needed to access map outside of setupMap
export default {
    name: 'SimpleMap',
    props: ['fire', 'station', 'centerStation', 'loaded'],
    data(){
        return {
            /**
             * Leaflet icon for use with RAWS station markers.
             */
            rawsIcon: L.icon({iconUrl: '/raws_station.svg', iconSize: [64,64]}),
            /**
             * Leaflet marker for given RAWS station.
             */
            rawsMarker: L.marker(),
            /**
             * Leaflet marker for given fire.
             */
            fireMarker: L.marker(),
            /**
             * Leaflet geoJSON object for given fire shape.
             */
            shape: L.geoJSON(),
        }
    },
    methods: {
        /**
         * Initializes the Leaflet map and stores it into map Array.
         */
        setupMap(){
            const mapDiv = L.map("simpleMap", { minZoom: 8 });
            L.tileLayer(
                "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                    attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    maxZoom: 20,
                }
            ).addTo(mapDiv);
            map[0] = mapDiv;
        },
        /**
         * Adds given fire to the map and stores it into fireMarker.
         */
        addFire(){
            let newLayer = L.marker([this.fire['LOCATION']['coordinates'][1], this.fire['LOCATION']['coordinates'][0]], {
                icon: this.generateIcon(this.fire["FIRE_SIZE_CLASS"], (this.fire["MTBS_ID"] != null ? true : false))
            }).bindPopup(`FIRE_NAME: ${this.fire["FIRE_NAME"] != null ? this.fire["FIRE_NAME"] : "None" }<br>FOD_ID: ${this.fire["FOD_ID"]}<br>${this.fire["MTBS_ID"] != null ? ("MTBS_ID: " + this.fire["MTBS_ID"] + "<br>") : "" }NWCG_GENERAL_CAUSE: ${this.fire["NWCG_GENERAL_CAUSE"]}`);
            newLayer.addTo(map[0]);
            this.fireMaker = newLayer;
            map[0].setView([this.fire['LOCATION']['coordinates'][1], this.fire['LOCATION']['coordinates'][0]], 9);
            this.fireReady = true;
        },
        /**
         * Adds given (if applicable) fire shape to map and stores it into shape.
         */
        addShape(){
            let newLayer = L.geoJSON(this.fire.SHAPE).addTo(map[0]);
            this.shape = newLayer;
            map[0].fitBounds(newLayer.getBounds());
        },
        /**
         * Adds given RAWS station to map and stores it in rawsMarker.
         * @param {Boolean} center - if true, prompts map to center on RAWS marker.
         */
        addRAWS(center){
            let newLayer = L.marker([this.station['location']['coordinates'][1], this.station['location']['coordinates'][0]], {
                icon: this.rawsIcon
            }).bindPopup(`Name: ${this.station["name"]}<br>WIMS: ${this.station["wims_id"]}`);
            newLayer.addTo(map[0]);
            this.rawsMarker = newLayer;
            if(center){
                map[0].setView([this.station['location']['coordinates'][1], this.station['location']['coordinates'][0]], 14);
            }
            this.rawsReady = true;
        },
        /**
         * Generates a fire icon for use with a leaflet marker.
         * @param {str} fire_size - Size of fire indicated using NIFC standard classification.
         * @param {Boolean} shape - Whether or not the fire has a shape associated with it.
         */
        generateIcon(fire_size, shape){
            var size_chart = {
                'A': [20, 20], 
                'B': [24, 24], 
                'C': [28, 28], 
                'D': [32, 32], 
                'E': [36, 36], 
                'F': [40, 40], 
                'G': [44, 44] 
            };
            return L.icon({iconUrl: (shape == true ? '/fire_color.svg' : '/fire_black.svg'), iconSize: size_chart[fire_size] });
        }
    },
    mounted(){

    },
    watch: {
        /**
         * Initializes and adds fire to the map if supplied one. Also adds shape if applicable.
         */
        fire: function(){
            this.setupMap();
            this.addFire();
            if(this.fire.SHAPE != undefined && this.fire.SHAPE != null && this.fire.SHAPE.type != 'Point'){
                this.addShape();
            }
        },
        /**
         * Initializes and adds RAWS station to map once RAWSDetail is loaded. 
         * 
         * Prevents issues with initializing before container size is determined.
         */
        loaded: function(){
            if(this.fire == undefined || this.fire == null){
                //this is necessary to reinitialize the leaflet map if clicking from one details page directly to another
                this.setupMap();
            }
            this.addRAWS(this.centerStation);
        }
    }
}
</script>

<style scoped>
    #simpleMap {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
</style>