<template>
    <hr />
    <div class="footer">
        <p>&copy; 2022-2023 Arizona Board of Regents on behalf of the University of Arizona, Arizona Institute for Resilience</p>
    </div>
</template>

<script>
export default {
    name: "FooterMenu"
}
</script>

<style scoped>
    .footer{
        font-size: .8em;
        text-align: center;
    }
</style>