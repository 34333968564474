<template>
    <div class="loadingScreen" v-show="waitingForData">
        <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <p class="text-light">Fetching data...</p>
    </div>
    <MoreInformation 
        v-show="moreInformation.show" 
        v-on:close-window="closeMoreInformation" 
        :title="moreInformation.title" 
        :message="moreInformation.message" 
    />
    <MapFilters 
        v-on:update-map-filters="updateMapFilters" 
        v-on:update-toggles="updateToggles" 
        v-on:open-more-information="openMoreInformation" 
    />
    <DataExport 
        v-on:close-window="closeExportWindow" 
        v-show="showExportWindow" 
        :all-fires="sentdata.export.allFires"
        :wims_ids="sentdata.export.wims_ids"
        :fod_ids="sentdata.export.fod_ids"
        :start="sentdata.export.start"
        :end="sentdata.export.end"
        :user-token="userToken"
        />
    <MapTools 
        v-show="showTools" 
        :fires-ready="firesReady" 
        :page-length="pageLength"
        v-model:current-page="currPage"
        :pages="pages"
        v-on:hide-tools="showTools = false"
        v-on:change-length="changeLength"
        v-on:request-export-list="requestExportList" 
        v-on:open-more-information="openMoreInformation"
    />
    <FireMap 
        :request-list="requestList" 
        :fires-data="sentdata.fires" 
        :raws-data="sentdata.raws" 
        :shapes-data="sentdata.shapes" 
        v-on:update-map-bounds="updateMapBounds" 
        v-on:export-list="updateExportData" :toggles="toggles"
    />
</template>

<script>
// @ is an alias to /src
import FireMap from '@/components/FireMap.vue';
import MapFilters from '@/components/MapFilters.vue';
import DataExport from '@/components/DataExport.vue';
import MoreInformation from '@/components/MoreInformation.vue';
import MapTools from '@/components/MapTools.vue'

import { getCurrentUser } from 'vuefire';

const { DateTime } = require("luxon");

export default {
    name: 'MapView',
    components: {
        FireMap,
        MapFilters,
        DataExport,
        MoreInformation,
        MapTools
    },
    data() {
        return {
            /**
             * User token used when sending API requests.
             */
            userToken: null,
            /** Indicates whether the user token is actually ready. Prevents API requests from happening until true. */
            userTokenReady: false,
            /**
             * Contains the information that was last sent to the FireMap component.
             */
            sentdata: {
                raws: {},
                fires: {},
                shapes: {},
                export: {
                    wims_ids: [],
                    fod_ids: [],
                    allFires: []
                }
            },
            /**
             * Contains the information that was pulled from the API.
             * Differs from sentdata in that it might have more information than what was provided to the map.
             */
            data: {
                raws: {},
                fires: {},
                shapes: {},
                models: []
            },
            /**
             * Contains an Array of fires that have been filtered using filterFires().
             * Used to figure out what shapes need to be sent and also with the paginator as a subset of the larger set of data.
             * @method filterFires()
             */
            filteredFires: [],
            /**
             * Contains the mapbounds last sent by the FireMap component.
             */
            mapbounds: {
                east: -102.623291015625,
                north: 37.49229399862877,
                south: 30.344435586368462,
                west: -115.55419921875001
            },
            /**
             * Contains the last information used to pull fires from the API.
             */
            queried: {
                /**
                 * Last queried map bounds.
                 * Used mostly when pulling shapes, as the bounds might have changed while fires were being pulled.
                 */
                mapbounds: {},
                start: null,
                end: null,
            },
            /**
             * Object containing the filter options selected within the MapFilters component.
             * Used in filterFires() and ultimately the filteredFires Array.
             */
            filters: {},
            /**
             * Object containing the toggles from the MapFilters component.
             * Contains booleans that affect displayed fires, shapes and stations on the map.
             * Also affects whether the MapTools component is shown.
             */
            toggles: {},
            /**
             * Boolean to indicate whether fires are ready to be sent to the FireMap component.
             */
            sendFires: false,
            /**
             * Boolean to indicate whether RAWS stations are ready to be sent to the FireMap component.
             */
            sendRAWS: false,
            /**
             * Boolean to indicate whether fire shapes are ready to be sent to the FireMap component.
             */
            sendShapes: false,
            /**
             * Boolean to show the DataExport component..
             */
            showExportWindow: false,
            /**
             * Default amount of fires to show per page.
             * Gets sent to MapTools component, and will be updated if changed from that component.
             */
            pageLength: 250,
            /**
             * The number of pages for filtered fires.
             * Generated based on pageLength and filteredFires.length
             */
            pages: 0,
            /**
             * The current page being viewed by the user.
             * @model currentPage for MapTools component.
             */
            currPage: 0,
            /**
             * Boolean that when set to true will tell the FireMap component to send currently viewable fires and RAWS stations.
             * The returned data will be used with the DataExport component.
             */
            requestList: false,
            /**
             * Boolean to show the MapTools component to the user.
             */
            showTools: true,
            /**
             * Boolean indicating whether the fires have been pulled successfully from the API.
             * Used mostly to disable some features of MapTools until the fire data is available.
             */
            firesReady: false,
            /**
             * Message information for use with the MoreInformation component.
             * Components will prompt the view to send usinf this structure.
             */
            moreInformation: {
                title: '',
                message: [],
                /**
                 * Boolean to show the MoreInformation component to the user.
                 */
                show: false
            },
            /**
             * Boolean indicating whether a model list has been pulled.
             */
            modelsReady: false,
            /**
             * Boolean indicating that the map is still waiting for container to spin up.
             */
            waitingForData: true
        }
    },
    methods: {
        /**
         * Shows the MoreInformation component with information structured like the moreInformation Object.
         * @param {Object} data - A message object, structured like {title: '', message: []}
         * @var moreInformation
         */
        openMoreInformation(data){
            this.moreInformation.title = data.title;
            this.moreInformation.message = data.message;
            this.moreInformation.show = true;
        },
        /**
         * Closes the MoreInformation component and clears the moreInformation Object.
         */
        closeMoreInformation(){
            this.moreInformation.show = false;
            this.moreInformation.title = '';
            this.moreInformation.message = '';
        },
        /**
         * Sends a boolean to FireMap to get the currently displayed fires and RAWS stations.
         */
        requestExportList(){
            this.requestList = true;
        },
        /**
         * Sends the list of fires and RAWS stations to be exported by the DataExport component.
         * @param {*} exportData - An object containing lists of FOD_IDs and WIMS_IDs to be exported.
         */
        updateExportData(exportData){
            var allFiresWithinBounds = [];
            for(let i = 0; i < this.filteredFires.length; i++){
                if(this.coordinatesWithinBounds(this.mapbounds, this.filteredFires[i].LOCATION.coordinates)){
                    allFiresWithinBounds.push(this.filteredFires[i].FOD_ID)
                }
            }
            
            this.sentdata.export.allFires = allFiresWithinBounds;
            this.sentdata.export.wims_ids = exportData.wims_ids;
            this.sentdata.export.fod_ids = exportData.fod_ids;
            this.sentdata.export.start = this.filters.start;
            this.sentdata.export.end = this.filters.end
            this.showExportWindow = true;
            this.requestList = false;
        },
        /**
         * Closes the DataExport component.
         */
        closeExportWindow(){
            this.showExportWindow = false;
        },
        /**
         * Updates toggles for use with the FireMap and MapTools components.
         * @param {Object} toggles - Structured like {toggleName: true/false}
         */
        updateToggles(toggles){
            this.showTools = toggles.tools;
            this.toggles = toggles;
        },
        /**
         * Updates the mapbounds variable using a bounding box sent by the FireMap component.
         * @param {Object} mapBounds - Contains fields for north, east, south, and west along with a GeoJSON object.
         */
        updateMapBounds(mapBounds){
            this.mapbounds = mapBounds;
        },
        /**
         * Updates the filters for use with filterFires(), pullShapeData(), and pullFireData()
         * @param {Object} mapFilters 
         */
        updateMapFilters(mapFilters){
            this.filters = mapFilters;
        },
        /**
         * Fetches fire data from the API using a bounding box from the FireMap component, and start/end dates from the filters object.
         * Will also update the queried mapbounds and start/end dates.
         * Stores the returned data into data.fires, and also prompts to fill the filteredFires list using filterFires(), then sends the data to the map.
         */
        pullFireData() {
            this.waitingForData = true;
            this.queried.mapbounds = this.mapbounds;
            this.queried.start = this.filters.start;
            this.queried.end = this.filters.end;
            this.data.fires = {};
            let fire_url = `/api/fires/?start=${DateTime.fromISO(this.filters.start).toISO()}&end=${DateTime.fromISO(this.filters.end).toISO()}`;

            fetch(fire_url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                },
                body: JSON.stringify(this.queried.mapbounds.geojson)
            }).then(async response => {
                const data = await response.json();
                if(!response.ok) {
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                this.data.fires = data;
                this.filteredFires = this.filterFires(this.data.fires);
                this.sendFires = true;
                this.pullShapeData();
            })
        },
        /**
         * Called after new fires are pulled from pullFireData(). Fetches fire shape data from the API using queried.mapbounds and queried.start/end.
         * Stores returned information into data.shapes, and then prompts to send the data to the map.
         */
        pullShapeData(){
            this.data.shapes = {};
            let shape_url = `/api/fires/shapes?start=${DateTime.fromISO(this.queried.start).toISO()}&end=${DateTime.fromISO(this.queried.end).toISO()}`;
            fetch(shape_url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                },
                body: JSON.stringify(this.queried.mapbounds.geojson)
            }).then(async response => {
                const data = await response.json();
                if(!response.ok) {
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                this.data.shapes = data;
                this.sendShapes = true;
                this.firesReady = true;
                this.waitingForData = false;
            })
        },
        /**
         * Only called once when the map loads and pulls all RAWS stations and stores them in data.raws, then sends to the map.
         */
        pullRAWSData(){
            this.waitingForData = true;
            let raws_url = `/api/raws/`;
            fetch(raws_url,{
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok) {
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                this.data.raws = data;
                this.sendRAWS = true;
                this.waitingForData = false;
            });
        },
        /**
         * Compares two objects on each key in keys. Used to compare map bounds and start/end dates when pulling fires.
         * @param {Object} object1 
         * @param {Object} object2 
         * @param {Array} keys 
         * @returns true or false
         */
        compareKeys(object1, object2, keys){
            let samevalues = true;
            for(let i = 0; i < keys.length; i++){
                if(object1[keys[i]] != object2[keys[i]]){
                    samevalues = false;
                    break;
                }
            }
            return samevalues;
        },
        /**
         * Compares two bounding boxes to find if inner is within outer.
         * Uses north, south, east, and west as keys for comparison.
         * @param {Object} outer 
         * @param {Object} inner
         * @returns true or false
         */
        withinBounds(outer, inner){
            let withinbounds = true;
            if(inner.north < outer.north){
                withinbounds = false;
            }
            if(inner.south > outer.south){
                withinbounds = false;
            }
            if(inner.west > outer.west){
                withinbounds = false;
            } 
            if(inner.east < outer.east){
                withinbounds = false;
            }
            return withinbounds;
        },
        /**
         * Compares a bounding box and set of coordinates to find if the coordinates lie within the box.
         * Uses north, south, east, and west, along with 2 indeces from coordinates as keys for comparison.
         * @param {Object} boundingBox
         * @param {Object} coordinates
         * @returns true or false
         */
        coordinatesWithinBounds(boundingBox, coordinates){
            let withinbounds = true;
            if(coordinates[0] <= boundingBox.west){
                withinbounds = false;
            }
            if(coordinates[0] >= boundingBox.east){
                withinbounds = false;
            }
            if(coordinates[1] >= boundingBox.north){
                withinbounds = false;
            }
            if(coordinates[1] <= boundingBox.south){
                withinbounds = false;
            }
            return withinbounds;
        },
        /**
         * Checks fireData for valid fires (based on filters object) and returns them.
         * @param {Object} fireData 
         * @returns List of fires that meet filter criteria.
         */
        filterFires(fireData){
            this.mtbs_list = [];
            let returnData = [];
            let nwcg_general_cause = [
                'Power generation/transmission/distribution',
                'Natural',
                'Debris and open burning',
                'Missing data/not specified/undetermined',
                'Recreation and ceremony',
                'Equipment and vehicle use',
                'Arson/incendiarism',
                'Fireworks',
                'Other causes',
                'Railroad operations and maintenance',
                'Smoking',
                'Misuse of fire by a minor',
                'Firearms and explosives use'
            ];

            for(let i = 0; i < fireData.length; i++){
                let fireValid = true;
                if(this.filters.cause != 'any'){
                    if(nwcg_general_cause[this.filters.cause] != fireData[i]["NWCG_GENERAL_CAUSE"]){
                        fireValid = false;
                    }
                }
                if(fireValid == true){
                    returnData.push(fireData[i]);
                }
            }
            this.generatePages(this.pageLength, returnData.length);
            return returnData;
        },
        /**
         * Generates a list of shapes that have valid fires in the current page of filteredFires.
         * @param {Object} shapesData
         * @returns List of fire shapes that have MTBS IDs found in filteredFires.
         */
        filterShapes(shapesData){
            let mtbsList = [];
            let returnData = [];
            let firePage = this.filteredFires.slice(this.pageLength * (this.currPage-1), ((this.pageLength * this.currPage) <= this.filteredFires.length ? (this.pageLength * this.currPage) : this.filteredFires.length));
            for(let i = 0; i < firePage.length; i++){
                if(firePage[i]["MTBS_ID"] != null){
                    mtbsList.push(firePage[i]["MTBS_ID"]);
                }
            }
            for(let i = 0; i < shapesData.length; i++){
                if(mtbsList.includes(shapesData[i]['MTBS_ID'])){
                    if(shapesData[i].shape.type != 'Point'){
                        returnData.push(shapesData[i]);
                    }
                }
            }
            return returnData;
        },
        /**
         * Generates the number of pages to split the filteredFires array by.
         * @param {Number} perPage 
         * @param {Number} dataLength 
         */
        generatePages(perPage, dataLength){
            this.pages = Math.ceil(dataLength/perPage);
            this.currPage = 1;        
        },
        /**
         * Called to update the number of pages when the pageLength variable changes.
         * @param {Number} value
         */
        changeLength(value){
            this.pageLength = value;
            if(this.firesReady == true){
                this.generatePages(this.pageLength, this.filteredFires.length);
                this.sendFires = true;
            }
        },
        /**
         * Pulls a list of available ML models.
         * 
         * @param {Boolean} pingContainer - Used to send a request to an API endpoint 
         * that reaches the model server to spin it up if asleep.
         */
        pullModelList(pingContainer){
            var url = '/api/raws/predictions/models';
            const self = this;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.userToken
                }
            }).then(async response => {
                const data = await response.json();
                if(!response.ok) {
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                }
                this.data.models = data;
            }).then(function () {
                self.modelsReady = true;
                if(pingContainer){
                    //used to ping the cloud run instance to spin up while a user is looking at the map
                    //essentially just pulls the first model on the list's metadata
                    var splitmodel = self.data.models[0].model.split('-')
                    fetch(`/api/raws/predictions/${splitmodel[0]}/${splitmodel[1]}/${splitmodel[2]}/metadata`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": self.userToken
                        }
                    }).then(async response => {
                        const data = response.json();
                        if(!response.ok){
                            const error = (data && data.message) || response.statusText;
                            return Promise.reject(error);
                        }
                    })
                }
            })
        },
    },
    mounted(){
        if(process.env.VUE_APP_DEV_MODE == "true"){
            //env variables are strings, hence the check above
            console.log("Running in developer mode.");
            this.userToken = null;
            this.userTokenReady = true;
            this.firesReady = true;
            this.pullFireData();
            this.pullRAWSData();
            this.pullModelList(true);
        } else {
            //On mount, pull in RAWS data (won't be requeried later).
            const self = this;
            getCurrentUser().then(async function (userInfo) {
                let user = await userInfo;
                user.getIdToken().then(async function(token) {
                    self.userToken = token;
                    self.userTokenReady = true;
                    self.firesReady = false;
                    self.pullFireData();
                }).catch(function (error) {
                    console.log(error)
                }).then(function () {
                    self.pullRAWSData();
                    self.pullModelList(true);
                });
            }).catch( function (error) {
                console.log(error);
            });
        }
    },
    watch: {
        /**
         * Updates data based on filter changes. Fires won't be requeried if mapbounds are within the previously queried map bounds, same if the start/end date don't change.
         * Will re-filter fires if not querying new ones.
         */
        filters: function(){
            if(this.userTokenReady){
                if((!this.compareKeys(this.mapbounds, this.queried.mapbounds, ['north','east','south','west']) && !this.withinBounds(this.mapbounds, this.queried.mapbounds)) || !this.compareKeys(this.filters, this.queried, ['start', 'end'])){
                    this.firesReady = false;
                    this.pullFireData(); 
                } else {
                    this.filteredFires = this.filterFires(this.data.fires);
                    this.sendFires = true;
                }
                this.sendRAWS = true; // this is just a trigger to show/hide the already pulled raws stations
            }
        },
        /**
         * Sends fires to the map by slicing the filteredFires array.
         */
        sendFires: function(){
            if(this.sendFires == true){
                this.sentdata.fires = this.filteredFires.slice(this.pageLength * (this.currPage-1), ((this.pageLength * this.currPage) <= this.filteredFires.length ? (this.pageLength * this.currPage) : this.filteredFires.length));
                this.sendFires = false;
                this.sendShapes = true;
            }
        },
        /**
         * Sends RAWS stations to the map.
         */
        sendRAWS: function(){
            if(this.sendRAWS == true){
                this.sentdata.raws = this.data.raws;
                this.sendRAWS = false;
            }
        },
        /**
         * Sends fire shapes to the map, filtered by filterShapes().
         */
        sendShapes: function(){
            if(this.sendShapes == true){
                this.sentdata.shapes = this.filterShapes(this.data.shapes);
                this.sendShapes = false;
            }
        },
        /**
         * Prompts to re-send fires when currPage is changed.
         */
        currPage: function(){
            this.sendFires = true;
        },
        /**
         * Adjusts the title of the webpage to the one listed in the router.
         */
         $route: {
            immediate: true,
            handler(to){
                document.title = to.meta.title || 'Fire Weather';
            }
        }
    }
}
</script>

<style>
.loadingScreen {
    z-index: 1003;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0,0.5);
    padding-top: 3em;
}
</style>