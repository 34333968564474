<template>
    <div class="moreinformation-container">
        <div class="moreinformation">
            <button v-on:click="closeWindow" class="btn-close" type="button" aria-label="Close"></button>
            <hr />
            <h2>{{ title }}</h2>
            <p v-for="line in message" v-bind:key="line">{{ line }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MoreInformation',
    props: ['title', 'message', 'buttonText'],
    emits: ['closeWindow'],
    data(){
        return {

        }
    },
    methods: {
        /**
         * Closes the message widow.
         * @event closeWindow
         */
        closeWindow(){
            this.$emit('closeWindow');
        }
    }
}
</script>

<style scoped>
.moreinformation-container {
    z-index: 2000;
    position: fixed;
    background-color: rgb(0,0,0,0.5);
    width: 100%;
    height: 100%;
}
.moreinformation {
    background-color: white;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    max-width: 600px;
    margin: auto;
    text-align: left;
}

.moreinformation p {
    width: 100%;
}

.openButton {
    font-size: .8em;
    position: relative;
    top: -.4em;
    left: .3em;
    color: white;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.openButton:hover {
    font-weight: bold;
}
</style>