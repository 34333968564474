<template>
    <div class="spinner-border mt-4" role="status" v-if="!doneLoading">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div class="container mt-4" v-if="doneLoading">
        <h1 class="text-start h4">Authentication</h1>
        <hr />
        <div v-if="!userLoggedIn && !resetPassword">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <form v-on:submit.prevent="signin();">
                <div class="form-floating mb-3">
                    <input type="email" id="email" class="form-control" v-model="email" placeholder="Email address" autocomplete="true">
                    <label for="email">Email address</label>
                </div>
                <div class="form-floating mb-3">
                    <input type="password" id="password" class="form-control" v-model="password" placeholder="Password" autocomplete="true">
                    <label for="password">Password</label>
                </div>
                <div>
                    <p class="small text-end"><button type="button" @click="resetPassword = true; password = '';" class="btn btn-link btn-sm">Forgot password?</button></p>
                </div>
                <div class="d-grid">
                    <button type="submit" class="btn btn-primary btn-lg btn-block">Login</button>
                </div>
            </form>
        </div>
        <div v-if="!userLoggedIn && resetPassword">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <form v-on:submit.prevent="resetUserPassword();" v-if="!resetEmailSent">
                <div class="form-floating mb-3">
                    <input type="email" id="reset-email" class="form-control" v-model="email" placeholder="Email address">
                    <label for="reset-email">Email address</label>
                </div>
                <div>
                    <p class="small text-end"><button type="button" @click="resetPassword = false; password = '';" class="btn btn-link btn-sm">Return to sign in</button></p>
                </div>
                <div class="d-grid">
                    <button type="submit" class="btn btn-primary btn-lg btn-block">Reset password</button>
                </div>
            </form>
            <div v-if="resetEmailSent">
                <div class="alert alert-success">Password reset email successfully sent.</div>
                <p class="small text-start"><strong>Note:</strong> Please check your spam or junk email folders. If the email account doesn't exist, an e-mail won't be sent.</p>
                <div class="d-grid">
                    <button type="button" class="btn btn-primary btn-lg btn-block" @click="returnToSignIn()">Back to sign in</button>
                </div>
            </div>
        </div>
        <div v-if="userLoggedIn">
            <p>You are currently logged in as {{ user.providerData[0].email }}.</p>
            <div class="d-grid" v-if="router.currentRoute.value.query.redirect">
                <a :href="router.currentRoute.value.query.redirect" class="btn btn-primary btn-lg">Continue to site</a>
            </div>
            <div class="d-grid" v-if="!router.currentRoute.value.query.redirect">
                <button @click="logout()" class="btn btn-primary btn-lg">Logout</button>
            </div>
        </div>
    </div>
</template>

<script setup>
// router needs to be pulled before the rest of this script, hence the setup attribute.
import { useRouter } from 'vue-router';
const router = useRouter();
</script>

<script>
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { getCurrentUser, useFirebaseAuth } from 'vuefire';

const auth = useFirebaseAuth();

export default {
    data() {
        return {
            /**
             * Error message string displayed to the user.
             */
            error: '',
            /**
             * Model variable for the email address field.
             */
            email: '',
            /**
             * Model variable for the password field.
             */
            password: '',
            /**
             * Boolean representing whether or not the form is done loading.
             * Mostly used to wait until the user is verified to be logged in or not.
             */
            doneLoading: false,
            /**
             * Stores the user information pulled when a user is logged in.
             */
            user: null,
            /**
             * Boolean representing whether to show the reset password form or the standard login form.
             */
            resetPassword: false,
            /**
             * Boolean representing if the password reset email was successfully sent after submission.
             */
            resetEmailSent: false
        }
    },
    methods: {
        /**
         * Attempts to sign into and account using user-provided credentials.
         */
        signin(){
            this.doneLoading = false;
            const self = this;
            signInWithEmailAndPassword(auth, this.email, this.password).then(() => {
                self.error = null;
                //successful sign in
                self.getUser();
            }).catch(() => {
                self.error = "Failed to sign in. Please try again.";
                self.doneLoading = true;
            });
        },
        /**
         * Attempts to sign out of the currently signed in user account.
         */
        logout(){
            this.doneLoading = false;
            const self = this;
            signOut(auth).then(() => {
                self.user = null;
                self.password = '';
                self.getUser();
            }).catch(() => {
                self.error = "Failed to log out. Please refresh and try again.";
                self.doneLoading = true;
            });
        },
        /**
         * Gets the currently logged in user.
         */
        getUser(){
            const self = this;
            getCurrentUser().then(async function (userInfo) {
                self.user = await userInfo;
                self.doneLoading = true;
            }).catch( function (error) {
                console.log(error);
            });
        },
        /**
         * Sends a password reset request to the user-provided email address.
         */
        resetUserPassword(){
            this.doneLoading = false;
            const self = this;
            sendPasswordResetEmail(auth, this.email).then(() => {
                self.doneLoading = true;
                self.resetEmailSent = true;
                self.error = '';
            }).catch(() => {
                self.doneLoading = true;
                self.error = 'Password reset failed. Please try again.';
            });
        },
        /**
         * Resets the authentication screen to the default login form.
         */
        returnToSignIn(){
            this.doneLoading = false;
            this.message = null;
            this.error = null;
            this.resetPassword = false;
            this.password = '';
            this.resetEmailSent = false;
            this.doneLoading = true;
        }
    },
    mounted(){
        this.getUser();
    },
    computed: {
        /**
         * Boolean representing whether or not the user is currently logged in.
         */
        userLoggedIn(){
            if(this.user == null){
                return false;
            } else {
                return true;
            }
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 400px;
    margin: auto;
}
</style>