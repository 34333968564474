<template>
    <div class="container">
        <div class="col" v-for="col in dataColumns" v-bind:key="col">
            <div class="data" v-for="item in col" v-bind:key="item"><strong>{{ item[0] }}:</strong> {{ item[1] }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemDetails',
    components: { },
    props: ['data', 'cols'],
    data() {
        return {
            /**
             * An Array of columns containing the pieces of data that go in each.
             */
            dataColumns: []
        }
    },
    methods: {
        /**
         * Generates columns for each entry in the data object prop based on cols prop.
         */
        generateColumns(){
            this.dataColumns = [];
            let colBreak = Math.ceil(this.data.length / this.cols);
            for (let i = 0; i < this.cols; i++){
                this.dataColumns.push(this.data.slice(i * colBreak, i * colBreak + colBreak))
            }
        }
    },
    watch: {
        /**
         * Generates columns when data is provided.
         */
        data: function(){
            this.generateColumns();
        }
    }
}
</script>

<style scoped>

.container {
    display: block;
    width: 100%;
}

.col {
    text-align: left;

}

.data {
    box-sizing: border-box;
    padding: .5em;
}

@media screen and (min-width: 600px){
    .container {
        display: flex;
        box-sizing: border-box;
    }
    .col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}

</style>