<template>
    <div :id="title"></div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
    name: 'LineGraph',
    components: { },
    props: ['data', 'title', 'layout'],
    emits: ['graphReady'],
    data() {
        return {
            /**
             * An object containing data being shown in the graph.
             */
            graphData: {},
        }
    },
    methods: {
        /**
         * Generates a plotly linegraph and tells parent the graph is ready.
         * @event graphReady
         */
        generateGraph(){
            let layout_trace = {};
            if(this.layout == undefined){
                layout_trace = {
                    title: this.title,
                    xaxis: {
                        title: 'Date/Time (UTC)',
                        type: 'date'
                    },
                    yaxis: {
                        title: 'Value'
                    }
                }
            } else {
                layout_trace = this.layout;
            }

            Plotly.newPlot(this.title, this.graphData, layout_trace);
            this.$emit('graphReady', true);
        }
    },
    watch: {
        /**
         * Updates the graphData and re-renders the plot when new data is provided.
         */
        data: function() {
            this.graphData = this.data;
            this.generateGraph();
        }
    }
}
</script>

<style scoped>
#graph {
    width: 100%;
    display: block;
    box-sizing: border-box;
}
</style>