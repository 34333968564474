<template>
    <div id="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
/**
 * Array containing the main Leaflet map stored as map[0].
 */
const map = []; //needed to access map outside of setupMap

export default {
    name: "FireMap",
    props: ['firesData', 'rawsData', 'shapesData', 'toggles', 'requestList'],
    emits: ['updateMapBounds', 'exportList'],
    data() {
        return {
            /**
             * The default center for the map.
             */
            center: [33.99, -109.088],
            /**
             * Object containing Leaflet icons.
             */
            icons: {
                /**
                 * Leaflet icon for use with RAWS station markers.
                 */
                raws_icon: L.icon({iconUrl: 'raws_station.svg', iconSize: [64,64]}),
            },
            /**
             * A Map() containing Array indices and FOD_IDs. 
             * For use in identifying an FOD_ID for a specific fire marker.
             */
            fod_map: new Map(),
            /**
             * A Map() containing Array indices and WIMS_IDs. 
             * For use in identifying an WIMS_ID for a specific RAWS marker.
             */
            wims_map: new Map(),
            /**
             * A Map() containing Array indices and MTBS_IDs. 
             * For use in identifying an MTBS_ID for a specific fire shape.
             */
            mtbs_map: new Map(),
            /**
             * An Array containing all fire markers on the map.
             */
            fire_markers: [],
            /**
             * An Array containing all fire shapes on the map.
             */
            shape_layers: [],
            /**
             * An Array containing all RAWS station markers on the map.
             */
            raws_markers: [],
            /**
             * Contains the layer groups for RAWS stations, fires, and fire shapes.
             * For use when clearing the map.
             */
            map_layers: {
                fires: L.layerGroup(),
                raws: L.layerGroup(),
                shapes: L.layerGroup()
            }
        }
    },
    methods: {
        /**
         * Initializes the Leaflet map and stores it into map Array.
         */
        setupMap() {
            const mapDiv = L.map("mapContainer", { minZoom: 5 }).setView(this.center, 8);
            L.tileLayer(
                "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                    attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | &copy; 2022-2023 Arizona Board of Regents on behalf of the University of Arizona, Arizona Institute for Resilience',
                    maxZoom: 18,
                }
            ).addTo(mapDiv);
            map.push(mapDiv);
        },
        /**
         * Sends the list of viewable fires and RAWS stations (FOD IDs and WIMS IDs, respectively) to the parent component.
         * @event exportList
         */
        sendExportList(){
            let fod_ids = [];
            let wims_ids = [];
            let bounds = map[0].getBounds();
            this.sendMapBounds();
            for(let i = 0; i < this.fire_markers.length; i++){
                this.fire_markers[i].eachLayer(layer => {
                    if(bounds.contains(layer.getLatLng())){
                        fod_ids.push(layer.options.fod_id);
                    }
                });
            }
            for(let i = 0; i < this.raws_markers.length; i++){
                this.raws_markers[i].eachLayer(layer => {
                    if(bounds.contains(layer.getLatLng())){
                        wims_ids.push(layer.options.wims_id);
                    }
                });                
            }
            let export_data = {
                fod_ids: fod_ids,
                wims_ids: wims_ids
            };
            this.$emit('exportList', export_data);
        },
        /**
         * Generates a fire icon for use with a leaflet marker.
         * @param {str} fire_size - Size of fire indicated using NIFC standard classification.
         * @param {Boolean} shape - Whether or not the fire has a shape associated with it.
         */
        generateIcon(fire_size, shape){
            var size_chart = {
                'A': [20, 20], 
                'B': [24, 24], 
                'C': [28, 28], 
                'D': [32, 32], 
                'E': [36, 36], 
                'F': [40, 40], 
                'G': [44, 44] 
            };
            return L.icon({iconUrl: (shape == true ? 'fire_color.svg' : 'fire_black.svg'), iconSize: size_chart[fire_size] });
        },
        /**
         * Sends the current map bounds to the parent component.
         * @event updateMapBounds
         */
        sendMapBounds() {
            let bounds = map[0].getBounds();
            let bounds_blob = {
                north: bounds.getNorth(),
                east: bounds.getEast(),
                south: bounds.getSouth(),
                west: bounds.getWest(),
                geojson: L.rectangle(bounds).toGeoJSON()
            };
            this.$emit('updateMapBounds', bounds_blob);
        },
        /**
         * Clears fire markers from the map and associated variables.
         */
        clearFires(){
            map[0].closePopup();
            map[0].removeLayer(this.map_layers.fires);
            this.fire_markers = [];
            this.fod_map.clear();
        },
        /**
         * Generates a marker for each fire in firesData and adds them to the map.
         */
        showFires(){
            this.clearFires();
            const self = this;
            for(let i = 0; i < this.firesData.length; i++){
                var newLayer = L.geoJSON(this.firesData[i]["LOCATION"], {
                    pointToLayer: function(feature, latlng){
                        return new L.marker(latlng, { 
                            type: 'marker', 
                            fod_id: self.firesData[i]["FOD_ID"],
                            icon: self.generateIcon(self.firesData[i]["FIRE_SIZE_CLASS"], (self.firesData[i]["MTBS_ID"] != null ? true : false))
                        })
                    },               
                }).bindPopup(`FIRE_NAME: ${this.firesData[i]["FIRE_NAME"] != null ? this.firesData[i]["FIRE_NAME"] : "None" }<br>FOD_ID: ${this.firesData[i]["FOD_ID"]}<br>${this.firesData[i]["MTBS_ID"] != null ? ("MTBS_ID: " + this.firesData[i]["MTBS_ID"] + "<br>") : "" }NWCG_GENERAL_CAUSE: ${this.firesData[i]["NWCG_GENERAL_CAUSE"]}<br><a href="/fire/${this.firesData[i]["FOD_ID"]}">More Information</a>`);
                this.fire_markers[i] = newLayer;
                this.fod_map.set(this.firesData[i]["FOD_ID"], i);
            }
            this.map_layers.fires = L.layerGroup(this.fire_markers);
            if(this.toggles.fires){
                this.map_layers.fires.addTo(map[0]);
            }
        },
        /**
         * Clears RAWS station markers from the map and associated variables.
         */
        clearRAWS(){
            map[0].closePopup();
            map[0].removeLayer(this.map_layers.raws);
            this.raws_markers = [];
            this.wims_map.clear();
        },
        /**
         * Generates a marker for each RAWS station in rawsData and adds them to the map.
         */
        showRAWS(){
            this.clearRAWS();
            const self = this;
            for(let i = 0; i < Object.keys(this.rawsData).length; i++){
                var newLayer = L.geoJSON(this.rawsData[i]["location"], {
                    pointToLayer: function(feature, latlng){
                        return new L.marker(latlng, { type: 'marker', wims_id: self.rawsData[i]["wims_id"], icon: self.icons.raws_icon });
                    }
                }).bindPopup(`Name: ${this.rawsData[i]["name"]}<br>WIMS: ${this.rawsData[i]["wims_id"]}<br><a href="/raws/${this.rawsData[i]["wims_id"]}">More Information</a>`);
                this.raws_markers[i] = newLayer;
                this.wims_map.set(this.rawsData[i]["wims_id"], i);
            }
            this.map_layers.raws = L.layerGroup(this.raws_markers);
            if(this.toggles.raws){
                this.map_layers.raws.addTo(map[0]);
            }
        },
        /**
         * Clears fire shapes from the map and associated variables.
         */
        clearShapes(){
            map[0].closePopup();
            map[0].removeLayer(this.map_layers.shapes);
            this.shape_layers = [];
            this.mtbs_map.clear();
        },
        /**
         * Adds each fire shape in shapesData to the map.
         */
        showShapes(){
            this.clearShapes();
            for(let i = 0; i < this.shapesData.length; i++){
                if(this.shapesData[i].shape.type != "Point"){
                    let newLayer = L.geoJSON(this.shapesData[i].shape, {type: 'shape', mtbs_id: this.shapesData[i]["MTBS_ID"]}).bindPopup(`MTBS_ID: ${this.shapesData[i]["MTBS_ID"]}`);
                    this.shape_layers.push(newLayer);
                    this.mtbs_map.set(this.shapesData[i]["MTBS_ID"], (this.shape_layers.length - 1));
                }
                
            }
            this.map_layers.shapes = L.layerGroup(this.shape_layers);
            if(this.toggles.shapes){
                this.map_layers.shapes.addTo(map[0]);
            }
        }
    },
    mounted() {
        //Initialize the map on load and immediately send the map bounds to the parent component.
        this.setupMap();
        this.sendMapBounds();
        //Setup event listener to automatically send map bounds when the view changes.
        map[0].on('moveend', this.sendMapBounds);
    },
    watch: {
        /**
         * Adds fire data to the map based on firesData.
         */
        firesData: function() {
            this.showFires();
        },
        /**
         * Adds RAWS station data to the map based on rawsData.
         */
        rawsData: function() {
            this.showRAWS();
        },
        /** 
         * Adds fire shape data to the map based on shapesData.
         */
        shapesData: function() {
            this.showShapes();
        },
        /**
         * Sends a list of viewable fires to the parent component when true.
         * @event exportList
         */
        requestList: function(){
            if(this.requestList){
                this.sendExportList();
            }
        },
        /**
         * Shows/hides layers based on toggles from MapFilters component.
         */
        toggles: function() {
            if(!this.toggles.fires){
                this.clearFires();
            } else {
                this.showFires();
            }
            if(!this.toggles.shapes){
                this.clearShapes();
            } else {
                this.showShapes();
            }
            if(!this.toggles.raws){
                this.clearRAWS();
            } else {
                this.showRAWS();
            }
        }
    }
};

</script>

<style scoped>
    #mapContainer {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    }

    .export {
        z-index: 1000;
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
</style>