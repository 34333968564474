<template>
    <button id="openFilters" v-on:click="showFilters = true" v-show="!showFilters" title="Open Menu">☰</button>
    <div id="details" v-show="showFilters" >
        <div data-bs-theme="dark">
            <button id="closeFilters" type="button" class="btn-close" aria-label="Close" v-on:click="showFilters = false"></button>
        </div>
        <hr />
        <form v-on:submit.prevent="sendUpdatedFilters()">
            <div id="filters">
                <h2>Filters</h2>
                <label for="cause">Cause:</label>
                <select id="cause" v-model="causeSelect.selected">
                    <option v-bind:key="option" v-for="option in causeSelect.options" :value="option.value">
                    {{ option.text }}
                    </option>
                </select>
                <label for="start">Start Date: </label>
                <input type="date" id="start" v-model="start" min="2000-01-01" max="2020-12-31"><br>
                <label for="end">End Date: </label>
                <input type="date" id="end" v-model="end" min="2000-01-01" max="2020-12-31">
            </div>
            <button type="submit" class="btn btn-secondary">Update Map</button>
        </form>
        <hr />
        <div id="toggles">
            <h2>Toggles</h2>
            <input type="checkbox" id="showShapes" v-model="showShapes" v-on:change="sendToggles"/>
            <label for="showShapes">Shapes</label><button v-on:click="openMessage(messages.shapes)" class="moreInfoButton" title="More information about shapes.">&#9432;</button><br />
            <input type="checkbox" id="showFires" v-model="showFires" v-on:change="sendToggles" />
            <label for="showFires">Fires</label><button v-on:click="openMessage(messages.fires)" class="moreInfoButton" title="More information about fires.">&#9432;</button><br />
            <input type="checkbox" id="showRAWS" v-model="showRAWS" v-on:change="sendToggles" />
            <label for="showRAWS">RAWS Stations</label><button v-on:click="openMessage(messages.raws)" class="moreInfoButton" title="More information about RAWS stations.">&#9432;</button><br />
            <input type="checkbox" id="showTools" v-model="showTools" v-on:change="sendToggles" />
            <label for="showTools">Tools</label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MapFilters",
        emits: ['updateMapFilters', 'updateToggles', 'openMoreInformation'],
        data() {
            return {
                /**
                 * Options and model for fire cause filter.
                 * @model causeSelect.selected
                 */
                causeSelect: {
                    options: [
                        {text: 'Any', value: 'any'},
                        {text: 'Power generation/transmission/distribution', value: 0},
                        {text: 'Natural', value: 1},
                        {text: 'Debris and open burning', value: 2},
                        {text: 'Missing data/not specified/undetermined', value: 3},
                        {text: 'Recreation and ceremony', value: 4},
                        {text: 'Equipment and vehicle use', value: 5},
                        {text: 'Arson/incendiarism', value: 6},
                        {text: 'Fireworks', value: 7},
                        {text: 'Other causes', value: 8},
                        {text: 'Railroad operations and maintenance', value: 9},
                        {text: 'Smoking', value: 10},
                        {text: 'Misuse of fire by a minor', value: 11},
                        {text: 'Firearms and explosives use', value: 12}
                    ],
                    selected: "any"
                },
                /**
                 * Boolean to show filters to user.
                 */
                showFilters: false,
                /**
                 * Boolean to show shapes on map.
                 */
                showShapes: false,
                /**
                 * Boolean to show fires on map.
                 */
                showFires: true,
                /**
                 * Boolean to show RAWS stations on map.
                 */
                showRAWS: true,
                /**
                 * Boolean to show tools to user.
                 */
                showTools: true,
                /**
                 * The start date to use when pulling fires for the map.
                 * @model
                 */
                start: "2020-12-01",
                /**
                 * The end date to use when pulling fires for the map.
                 * @model
                 */
                end: "2020-12-31",
                /**
                 * Messages to be sent to the MoreInformation component (handled by MapView).
                 */
                messages: {
                    shapes: {
                        title: "Shape simplification",
                        message:[`Shapes shown on this fire map are simplified for performance reasons. This means 
                                they aren't completely accurate to the reported burn perimeters.`,
                                `The shape will display without simplification in the fire details view.`] 
                    },
                    fires: {
                        title: "Pulling fires from database",
                        message:[`Orange icons represent fires with shapes pulled from the MTBS database. In most cases, this means there is a burn perimeter shape associated with the fire.`,
                                `Fires are pulled from the database using the current bounding box of the viewable map ordered by discovery date ascending. This means the first page would display the oldest fires, and the last page would display the most recent.`,
                                `The map will not requery fires if the current viewable bounding box is within the previously queried box unless the start and end dates are changed.`]
                    },
                    raws: {
                        title: "RAWS Stations",
                        message: ['Currently, all of the RAWS stations are queried when the map loads, meaning they are all diplayed independent of the bounding box search for fires and shapes.']
                    }
                }
            }
        },
        methods: {
            /**
             * Method to send message to MoreInformation component (handled by MapView).
             * @param {Object} data
             * @event openMoreInformation
             */
            openMessage(data){
                this.$emit('openMoreInformation', data);
            },
            /**
             * Gets called when Update Map button gets clicked and sends the new filters to the MapView.
             * @event updateMapFilters
             */
            sendUpdatedFilters(){
                let filter_blob = {
                    cause: this.causeSelect.selected,
                    start: this.start + 'T00:00:00',
                    end: this.end + 'T23:59:59'
                }
                this.$emit('updateMapFilters', filter_blob);
            },
            /**
             * Gets called when any of the toggles are changed and sends the updates to the MapView.
             * @event updateToggles
             */
            sendToggles(){
                let toggles_blob = {
                    fires: this.showFires,
                    shapes: this.showShapes,
                    raws: this.showRAWS,
                    tools: this.showTools
                }
                this.$emit('updateToggles', toggles_blob);
            }
        },
        mounted(){
            //Send the default filters and toggles to the MapView on first load.
            this.sendUpdatedFilters();
            this.sendToggles();
        }
    }
</script>

<style scoped>
#filters {
  width: 100%;
  max-width: 250px;
}

h2 {
    margin: 0;
    margin-bottom: .5em;
}

#filters label {
    font-size: .9em;
    display: block;
}

#filters input {
    margin-bottom: .5em;
    max-width: 95%;
}

#filters select {
    margin: 0;
    margin-bottom: .5em;
    width: 100%;
}

#filters button {
  margin: .5em;
  margin-top: 1em;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px #595959 solid;
}

#toggles {
  width: 100%;
}

#toggles input {
  margin: .5em;
}

#details {
    position: fixed;
    right: 0;
    top: 0;
    width: auto;
    max-height: 100%;
    background-color: rgb(37, 37, 37, 0.95);
    z-index: 1002;
    text-align: left;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 1em;
    margin: 0;
    color: white;
}

#openFilters {
    z-index: 1001;
    position: fixed;
    right: 0;
    top: 0;
    margin: .5em;
    background-color: white;
    border: 1px black solid;
    font-size: 2em;
    border-radius: 10%;
    padding: .1em .3em;
    padding-bottom: .25em;
    line-height: 1em;
}

#openFilters:hover {
    background-color: lightgrey;
}

.moreInfoButton {
    font-size: 1em;
    background: none;
    border: none;
    margin-left: .3em;
    padding: 0;
    color: white;
}

.moreInfoButton:hover {
    font-weight: bold;
    cursor: pointer;
}

.closeButton {
    background: none;
    border: none;
    padding: 0;
    margin: .1em 0;
    color: white;
    font-size: 1.3em;
}

.closeButton:hover{
    font-weight: bold;
    cursor: pointer;
}
</style>